#teampage .team-members, .service-page__contact-person .team-member, .service-page__contact--both, .service-page__media--both, .service-page__media .page-media__files li, .service-page__media .page-media__images, .service-page__text, .service-holder__children, .bath-showroom__top, #grasenhiller_appointmentpage form fieldset #FirstNameSurname,
#grasenhiller_appointmentpage form fieldset #EmailPhone, html .userform .step-navigation ul, .sticky-toolbar_links, .homepage--teaser-boxes .teaser-box .content, .homepage--teaser-boxes, .team--teaser_left .content-wrapper, .team--teaser, .team-members .team-member,
.search-results__list--TeamMember .search-results__list-items .team-member, .team-members,
.search-results__list--TeamMember .search-results__list-items, .service-preview, .reference-preview .outer-wrapper, .reference-preview, .reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step, .reference-detail__bottom, .reference-detail__top, .navigation--mobile .navigation__sub .navigation__sub-link, .navigation--mobile ul li, .header--inner-wrapper, form .field .middleColumn, .footer--inner-wrapper_top .footer--box:last-of-type .navigation--main ul, .footer--inner-wrapper_top, .footer--inner-wrapper_bottom, .footer--inner-wrapper, .page-3 main, .homepage--carrer, html .basic-holder__grand-children, .modular-content .module--textimage .textimage, .modular-content .module--grid .grid, .modular-content .module--gallery .gallery, .modular-content .module--downloads ul li, .modular-content .module--accordion .accordion__title, .modular-content .modular-content-columns, .team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container, .formatted-text .responsive-iframe-container,
[class*=__text] .responsive-iframe-container, .cf {
  *zoom: 1;
}
#teampage .team-members:before, .service-page__contact-person .team-member:before, .service-page__contact--both:before, .service-page__media--both:before, .service-page__media .page-media__files li:before, .service-page__media .page-media__images:before, .service-page__text:before, .service-holder__children:before, .bath-showroom__top:before, #grasenhiller_appointmentpage form fieldset #FirstNameSurname:before,
#grasenhiller_appointmentpage form fieldset #EmailPhone:before, html .userform .step-navigation ul:before, .sticky-toolbar_links:before, .homepage--teaser-boxes .teaser-box .content:before, .homepage--teaser-boxes:before, .team--teaser_left .content-wrapper:before, .team--teaser:before, .team-members .team-member:before,
.search-results__list--TeamMember .search-results__list-items .team-member:before, .team-members:before,
.search-results__list--TeamMember .search-results__list-items:before, .service-preview:before, .reference-preview .outer-wrapper:before, .reference-preview:before, .reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step:before, .reference-detail__bottom:before, .reference-detail__top:before, .navigation--mobile .navigation__sub .navigation__sub-link:before, .navigation--mobile ul li:before, .header--inner-wrapper:before, form .field .middleColumn:before, .footer--inner-wrapper_top .footer--box:last-of-type .navigation--main ul:before, .footer--inner-wrapper_top:before, .footer--inner-wrapper_bottom:before, .footer--inner-wrapper:before, .page-3 main:before, .homepage--carrer:before, html .basic-holder__grand-children:before, .modular-content .module--textimage .textimage:before, .modular-content .module--grid .grid:before, .modular-content .module--gallery .gallery:before, .modular-content .module--downloads ul li:before, .modular-content .module--accordion .accordion__title:before, .modular-content .modular-content-columns:before, .team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container:before, .formatted-text .responsive-iframe-container:before,
[class*=__text] .responsive-iframe-container:before, .cf:before {
  content: "";
  display: table;
}
#teampage .team-members:after, .service-page__contact-person .team-member:after, .service-page__contact--both:after, .service-page__media--both:after, .service-page__media .page-media__files li:after, .service-page__media .page-media__images:after, .service-page__text:after, .service-holder__children:after, .bath-showroom__top:after, #grasenhiller_appointmentpage form fieldset #FirstNameSurname:after,
#grasenhiller_appointmentpage form fieldset #EmailPhone:after, html .userform .step-navigation ul:after, .sticky-toolbar_links:after, .homepage--teaser-boxes .teaser-box .content:after, .homepage--teaser-boxes:after, .team--teaser_left .content-wrapper:after, .team--teaser:after, .team-members .team-member:after,
.search-results__list--TeamMember .search-results__list-items .team-member:after, .team-members:after,
.search-results__list--TeamMember .search-results__list-items:after, .service-preview:after, .reference-preview .outer-wrapper:after, .reference-preview:after, .reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step:after, .reference-detail__bottom:after, .reference-detail__top:after, .navigation--mobile .navigation__sub .navigation__sub-link:after, .navigation--mobile ul li:after, .header--inner-wrapper:after, form .field .middleColumn:after, .footer--inner-wrapper_top .footer--box:last-of-type .navigation--main ul:after, .footer--inner-wrapper_top:after, .footer--inner-wrapper_bottom:after, .footer--inner-wrapper:after, .page-3 main:after, .homepage--carrer:after, html .basic-holder__grand-children:after, .modular-content .module--textimage .textimage:after, .modular-content .module--grid .grid:after, .modular-content .module--gallery .gallery:after, .modular-content .module--downloads ul li:after, .modular-content .module--accordion .accordion__title:after, .modular-content .modular-content-columns:after, .team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container:after, .formatted-text .responsive-iframe-container:after,
[class*=__text] .responsive-iframe-container:after, .cf:after {
  content: "";
  display: table;
  clear: both;
}

.team-members .team-member__info a,
.search-results__list--TeamMember .search-results__list-items .team-member__info a {
  color: #003465;
  text-decoration: none;
}
.team-members .team-member__info a:hover,
.search-results__list--TeamMember .search-results__list-items .team-member__info a:hover {
  color: #01a4a9;
  text-decoration: underline;
}

#grasenhiller_homepage .main__text, #grasenhiller_homepage .page-title, .bath-showroom__bottom, .bath-showroom__top, #grasenhiller_bathshowroompage .page-title, #grasenhiller_bathshowroompage.page--no-banner .page-title, .homepage--teaser-boxes, #grasenhiller_referenceholder .page-title, #grasenhiller_referenceholder.page--no-banner .page-title, .homepage--carrer, .address-bar, .outer-wrapper {
  width: calc(100% - 20px);
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

html .userform .step-navigation ul li button, .sticky-toolbar_links a, .page-slider__caption-content > a, input[type=submit],
input[type=reset], #cookie-confirm__cancel, #cookie-confirm__submit, .team--teaser_left .content-wrapper .content-wrapper-inner p .text--button, .formatted-text .text--button,
[class*=__text] .text--button, .button {
  display: inline-block;
  color: #FFFFFF !important;
  padding: 13.3333333333px 20px;
  background: linear-gradient(45deg, #01a4a9 0%, #0154a2 100%);
  transition: all linear 0.2s;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 4px;
  line-height: 1.35em;
  font-weight: 400;
}
html .userform .step-navigation ul li button:hover, .sticky-toolbar_links a:hover, .page-slider__caption-content > a:hover, input[type=submit]:hover,
input[type=reset]:hover, #cookie-confirm__cancel:hover, #cookie-confirm__submit:hover, .team--teaser_left .content-wrapper .content-wrapper-inner p .text--button:hover, .formatted-text .text--button:hover,
[class*=__text] .text--button:hover, .button:hover {
  background: linear-gradient(45deg, #018b90 0%, #003465 100%) !important;
  color: #ffffff !important;
  text-decoration: none !important;
}
html .userform .step-navigation ul li button:focus, .sticky-toolbar_links a:focus, .page-slider__caption-content > a:focus, input[type=submit]:focus,
input[type=reset]:focus, #cookie-confirm__cancel:focus, #cookie-confirm__submit:focus, .team--teaser_left .content-wrapper .content-wrapper-inner p .text--button:focus, .formatted-text .text--button:focus,
[class*=__text] .text--button:focus, .button:focus {
  outline: none;
  border: none;
}

.homepage--teaser-boxes .teaser-box .content a, .reference-preview__right .reference-preview__text a, .button--small {
  color: #01a4a9;
  padding-left: 1.5em;
  padding-left: 0;
}
.homepage--teaser-boxes .teaser-box .content a:before, .reference-preview__right .reference-preview__text a:before, .button--small:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.homepage--teaser-boxes .teaser-box .content a:before, .reference-preview__right .reference-preview__text a:before, .button--small:before {
  margin-left: 0;
}
.homepage--teaser-boxes .teaser-box .content a:hover, .reference-preview__right .reference-preview__text a:hover, .button--small:hover {
  text-decoration: underline;
}

.button--white {
  background: #FFFFFF;
  color: #003465;
}
.button--white:hover {
  color: #FFFFFF;
}

html .userform .step-navigation ul li button, input[type=reset], .team--teaser_left .content-wrapper .content-wrapper-inner p .text--button-2, .formatted-text .text--button-2,
[class*=__text] .text--button-2, .button--neutral {
  background: #4b4b4b;
  display: inline-block;
  color: #FFFFFF !important;
  padding: 13.3333333333px 20px;
  transition: all linear 0.2s;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 4px;
  line-height: 1.35em;
  font-weight: 400;
}
html .userform .step-navigation ul li button:hover, input[type=reset]:hover, .team--teaser_left .content-wrapper .content-wrapper-inner p .text--button-2:hover, .formatted-text .text--button-2:hover,
[class*=__text] .text--button-2:hover, .button--neutral:hover {
  color: #ffffff;
  background: #3e3e3e !important;
  text-decoration: none !important;
}

.team--teaser_left .content-wrapper .content-wrapper-inner p ul.list--checklist, .formatted-text ul.list--checklist,
[class*=__text] ul.list--checklist {
  list-style: none;
  margin-left: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul.list--checklist li, .formatted-text ul.list--checklist li,
[class*=__text] ul.list--checklist li {
  display: block;
  padding-left: 1.5em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul.list--checklist li:before, .formatted-text ul.list--checklist li:before,
[class*=__text] ul.list--checklist li:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00c";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul.list--checklist li:before, .formatted-text ul.list--checklist li:before,
[class*=__text] ul.list--checklist li:before {
  margin-top: 0.125em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--discreet, .formatted-text .text--discreet,
[class*=__text] .text--discreet {
  color: #0154a2 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--highlight, .formatted-text .text--highlight,
[class*=__text] .text--highlight {
  color: #01a4a9;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background, .formatted-text .text--background,
[class*=__text] .text--background,
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background-2,
.formatted-text .text--background-2,
[class*=__text] .text--background-2,
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background-3,
.formatted-text .text--background-3,
[class*=__text] .text--background-3 {
  padding: 1.35rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background > *:last-child, .formatted-text .text--background > *:last-child,
[class*=__text] .text--background > *:last-child,
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background-2 > *:last-child,
.formatted-text .text--background-2 > *:last-child,
[class*=__text] .text--background-2 > *:last-child,
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background-3 > *:last-child,
.formatted-text .text--background-3 > *:last-child,
[class*=__text] .text--background-3 > *:last-child {
  margin-bottom: 0;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background, .formatted-text .text--background,
[class*=__text] .text--background,
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--inline-bg,
.formatted-text .text--inline-bg,
[class*=__text] .text--inline-bg {
  background: #01a4a9;
  color: #ffffff;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background-2, .formatted-text .text--background-2,
[class*=__text] .text--background-2,
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--inline-bg-2,
.formatted-text .text--inline-bg-2,
[class*=__text] .text--inline-bg-2 {
  background: #01a4a9;
  color: #ffffff;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--background-3, .formatted-text .text--background-3,
[class*=__text] .text--background-3,
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--inline-bg-3,
.formatted-text .text--inline-bg-3,
[class*=__text] .text--inline-bg-3 {
  background: #e4ebf1;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--small, .formatted-text .text--small,
[class*=__text] .text--small {
  font-size: 0.75rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--medium, .formatted-text .text--medium,
[class*=__text] .text--medium {
  font-size: 1.5rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .text--big, .formatted-text .text--big,
[class*=__text] .text--big {
  font-size: 2.8125rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p, .formatted-text p,
[class*=__text] p {
  min-height: 1.35em;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  overflow: hidden;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p.text-center, .formatted-text p.text-center,
[class*=__text] p.text-center {
  text-align: center;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p.text-left, .formatted-text p.text-left,
[class*=__text] p.text-left {
  text-align: left;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p.text-right, .formatted-text p.text-right,
[class*=__text] p.text-right {
  text-align: right;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p.text-justify, .formatted-text p.text-justify,
[class*=__text] p.text-justify {
  text-align: justify;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p code:not([class*=language-]), .formatted-text code:not([class*=language-]),
[class*=__text] code:not([class*=language-]) {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  font-family: monospace, san-serif;
  font-size: 0.75rem;
  padding: 20px;
  background: #ffffff;
  border-radius: 4px;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p pre:not([class*=language-]), .formatted-text pre:not([class*=language-]),
[class*=__text] pre:not([class*=language-]) {
  white-space: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  background: #e4ebf1;
  padding: 1.35rem;
  border-radius: 4px;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p pre[class*=language-], .formatted-text pre[class*=language-],
[class*=__text] pre[class*=language-] {
  padding: 1.35rem;
  margin: 0.75em 0 0 0;
  border-radius: 4px;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img[style*="max-width:"], .formatted-text img[style*="max-width:"],
[class*=__text] img[style*="max-width:"],
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed[style*="max-width:"],
.formatted-text .embed[style*="max-width:"],
[class*=__text] .embed[style*="max-width:"] {
  display: block;
  width: 100%;
  height: auto;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img.center, .formatted-text img.center,
[class*=__text] img.center, .team--teaser_left .content-wrapper .content-wrapper-inner p img.leftAlone, .formatted-text img.leftAlone,
[class*=__text] img.leftAlone, .team--teaser_left .content-wrapper .content-wrapper-inner p img.rightAlone, .formatted-text img.rightAlone,
[class*=__text] img.rightAlone,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.center,
.formatted-text .embed.center,
[class*=__text] .embed.center,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.leftAlone,
.formatted-text .embed.leftAlone,
[class*=__text] .embed.leftAlone,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.rightAlone,
.formatted-text .embed.rightAlone,
[class*=__text] .embed.rightAlone {
  display: block;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img.center + *, .formatted-text img.center + *,
[class*=__text] img.center + *, .team--teaser_left .content-wrapper .content-wrapper-inner p img.leftAlone + *, .formatted-text img.leftAlone + *,
[class*=__text] img.leftAlone + *, .team--teaser_left .content-wrapper .content-wrapper-inner p img.rightAlone + *, .formatted-text img.rightAlone + *,
[class*=__text] img.rightAlone + *,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.center + *,
.formatted-text .embed.center + *,
[class*=__text] .embed.center + *,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.leftAlone + *,
.formatted-text .embed.leftAlone + *,
[class*=__text] .embed.leftAlone + *,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.rightAlone + *,
.formatted-text .embed.rightAlone + *,
[class*=__text] .embed.rightAlone + * {
  clear: both;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img.center, .formatted-text img.center,
[class*=__text] img.center,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.center,
.formatted-text .embed.center,
[class*=__text] .embed.center {
  margin-left: auto;
  margin-right: auto;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img.leftAlone, .formatted-text img.leftAlone,
[class*=__text] img.leftAlone,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.leftAlone,
.formatted-text .embed.leftAlone,
[class*=__text] .embed.leftAlone {
  float: left;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img.rightAlone, .formatted-text img.rightAlone,
[class*=__text] img.rightAlone,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.rightAlone,
.formatted-text .embed.rightAlone,
[class*=__text] .embed.rightAlone {
  float: right;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img.left, .formatted-text img.left,
[class*=__text] img.left,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.left,
.formatted-text .embed.left,
[class*=__text] .embed.left {
  float: left;
  margin: 1em 1.35em 0.5em 0;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p img.right, .formatted-text img.right,
[class*=__text] img.right,
.team--teaser_left .content-wrapper .content-wrapper-inner p .embed.right,
.formatted-text .embed.right,
[class*=__text] .embed.right {
  float: right;
  margin: 1em 0 0.5em 1.35em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h1, .formatted-text h1,
[class*=__text] h1 {
  font-size: 3.4375rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h2, .formatted-text h2,
[class*=__text] h2 {
  font-size: 2.8125rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h3, .formatted-text h3,
[class*=__text] h3 {
  font-size: 2rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h4, .formatted-text h4,
[class*=__text] h4 {
  font-size: 1.5rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h5, .formatted-text h5,
[class*=__text] h5 {
  font-size: 1.25rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h6, .formatted-text h6,
[class*=__text] h6 {
  font-size: 1.125rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h1, .formatted-text h1,
[class*=__text] h1,
.team--teaser_left .content-wrapper .content-wrapper-inner p h2,
.formatted-text h2,
[class*=__text] h2,
.team--teaser_left .content-wrapper .content-wrapper-inner p h3,
.formatted-text h3,
[class*=__text] h3,
.team--teaser_left .content-wrapper .content-wrapper-inner p h4,
.formatted-text h4,
[class*=__text] h4,
.team--teaser_left .content-wrapper .content-wrapper-inner p h5,
.formatted-text h5,
[class*=__text] h5,
.team--teaser_left .content-wrapper .content-wrapper-inner p h6,
.formatted-text h6,
[class*=__text] h6 {
  font-family: "Saira", sans-serif;
  font-weight: 700;
  margin-top: 1.5em !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p > *:first-child, .formatted-text > *:first-child,
[class*=__text] > *:first-child {
  margin-top: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p > *:last-child, .formatted-text > *:last-child,
[class*=__text] > *:last-child {
  margin-bottom: 0;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p, .formatted-text p,
[class*=__text] p {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p > *:first-child, .formatted-text p > *:first-child,
[class*=__text] p > *:first-child {
  margin-top: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p .btn, .formatted-text p .btn,
[class*=__text] p .btn {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p p + ul, .formatted-text p + ul,
[class*=__text] p + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p p + ol, .formatted-text p + ol,
[class*=__text] p + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p p + h1, .formatted-text p + h1,
[class*=__text] p + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p p + h2, .formatted-text p + h2,
[class*=__text] p + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p p + h3, .formatted-text p + h3,
[class*=__text] p + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p p + h4, .formatted-text p + h4,
[class*=__text] p + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p p + h5, .formatted-text p + h5,
[class*=__text] p + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p p + h6, .formatted-text p + h6,
[class*=__text] p + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p p + table, .formatted-text p + table,
[class*=__text] p + table, .team--teaser_left .content-wrapper .content-wrapper-inner p p + div, .formatted-text p + div,
[class*=__text] p + div, .team--teaser_left .content-wrapper .content-wrapper-inner p p + blockquote, .formatted-text p + blockquote,
[class*=__text] p + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p p + code, .formatted-text p + code,
[class*=__text] p + code, .team--teaser_left .content-wrapper .content-wrapper-inner p p + pre, .formatted-text p + pre,
[class*=__text] p + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p blockquote, .formatted-text blockquote,
[class*=__text] blockquote {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p blockquote > *:first-child, .formatted-text blockquote > *:first-child,
[class*=__text] blockquote > *:first-child {
  margin-top: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + ul, .formatted-text blockquote + ul,
[class*=__text] blockquote + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + ol, .formatted-text blockquote + ol,
[class*=__text] blockquote + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + h1, .formatted-text blockquote + h1,
[class*=__text] blockquote + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + h2, .formatted-text blockquote + h2,
[class*=__text] blockquote + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + h3, .formatted-text blockquote + h3,
[class*=__text] blockquote + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + h4, .formatted-text blockquote + h4,
[class*=__text] blockquote + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + h5, .formatted-text blockquote + h5,
[class*=__text] blockquote + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + h6, .formatted-text blockquote + h6,
[class*=__text] blockquote + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + table, .formatted-text blockquote + table,
[class*=__text] blockquote + table, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + div, .formatted-text blockquote + div,
[class*=__text] blockquote + div, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + p, .formatted-text blockquote + p,
[class*=__text] blockquote + p, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + code, .formatted-text blockquote + code,
[class*=__text] blockquote + code, .team--teaser_left .content-wrapper .content-wrapper-inner p blockquote + pre, .formatted-text blockquote + pre,
[class*=__text] blockquote + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p code, .formatted-text code,
[class*=__text] code {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p code > *:first-child, .formatted-text code > *:first-child,
[class*=__text] code > *:first-child {
  margin-top: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p code + ul, .formatted-text code + ul,
[class*=__text] code + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p code + ol, .formatted-text code + ol,
[class*=__text] code + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p code + h1, .formatted-text code + h1,
[class*=__text] code + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p code + h2, .formatted-text code + h2,
[class*=__text] code + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p code + h3, .formatted-text code + h3,
[class*=__text] code + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p code + h4, .formatted-text code + h4,
[class*=__text] code + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p code + h5, .formatted-text code + h5,
[class*=__text] code + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p code + h6, .formatted-text code + h6,
[class*=__text] code + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p code + table, .formatted-text code + table,
[class*=__text] code + table, .team--teaser_left .content-wrapper .content-wrapper-inner p code + div, .formatted-text code + div,
[class*=__text] code + div, .team--teaser_left .content-wrapper .content-wrapper-inner p code + p, .formatted-text code + p,
[class*=__text] code + p, .team--teaser_left .content-wrapper .content-wrapper-inner p code + code, .formatted-text code + code,
[class*=__text] code + code, .team--teaser_left .content-wrapper .content-wrapper-inner p code + pre, .formatted-text code + pre,
[class*=__text] code + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p pre, .formatted-text pre,
[class*=__text] pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p pre > *:first-child, .formatted-text pre > *:first-child,
[class*=__text] pre > *:first-child {
  margin-top: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p pre + ul, .formatted-text pre + ul,
[class*=__text] pre + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + ol, .formatted-text pre + ol,
[class*=__text] pre + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + h1, .formatted-text pre + h1,
[class*=__text] pre + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + h2, .formatted-text pre + h2,
[class*=__text] pre + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + h3, .formatted-text pre + h3,
[class*=__text] pre + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + h4, .formatted-text pre + h4,
[class*=__text] pre + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + h5, .formatted-text pre + h5,
[class*=__text] pre + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + h6, .formatted-text pre + h6,
[class*=__text] pre + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + table, .formatted-text pre + table,
[class*=__text] pre + table, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + div, .formatted-text pre + div,
[class*=__text] pre + div, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + p, .formatted-text pre + p,
[class*=__text] pre + p, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + code, .formatted-text pre + code,
[class*=__text] pre + code, .team--teaser_left .content-wrapper .content-wrapper-inner p pre + pre, .formatted-text pre + pre,
[class*=__text] pre + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h1 + h1, .formatted-text h1 + h1,
[class*=__text] h1 + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + h2, .formatted-text h1 + h2,
[class*=__text] h1 + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + h3, .formatted-text h1 + h3,
[class*=__text] h1 + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + h4, .formatted-text h1 + h4,
[class*=__text] h1 + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + h5, .formatted-text h1 + h5,
[class*=__text] h1 + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + h6, .formatted-text h1 + h6,
[class*=__text] h1 + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + p, .formatted-text h1 + p,
[class*=__text] h1 + p, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + ul, .formatted-text h1 + ul,
[class*=__text] h1 + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + ol, .formatted-text h1 + ol,
[class*=__text] h1 + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + table, .formatted-text h1 + table,
[class*=__text] h1 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + div, .formatted-text h1 + div,
[class*=__text] h1 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + blockquote, .formatted-text h1 + blockquote,
[class*=__text] h1 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + code, .formatted-text h1 + code,
[class*=__text] h1 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + pre, .formatted-text h1 + pre,
[class*=__text] h1 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + h1, .formatted-text h2 + h1,
[class*=__text] h2 + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + h2, .formatted-text h2 + h2,
[class*=__text] h2 + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + h3, .formatted-text h2 + h3,
[class*=__text] h2 + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + h4, .formatted-text h2 + h4,
[class*=__text] h2 + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + h5, .formatted-text h2 + h5,
[class*=__text] h2 + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + h6, .formatted-text h2 + h6,
[class*=__text] h2 + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + p, .formatted-text h2 + p,
[class*=__text] h2 + p, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + ul, .formatted-text h2 + ul,
[class*=__text] h2 + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + ol, .formatted-text h2 + ol,
[class*=__text] h2 + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + table, .formatted-text h2 + table,
[class*=__text] h2 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + div, .formatted-text h2 + div,
[class*=__text] h2 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + blockquote, .formatted-text h2 + blockquote,
[class*=__text] h2 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + code, .formatted-text h2 + code,
[class*=__text] h2 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + pre, .formatted-text h2 + pre,
[class*=__text] h2 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + h1, .formatted-text h3 + h1,
[class*=__text] h3 + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + h2, .formatted-text h3 + h2,
[class*=__text] h3 + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + h3, .formatted-text h3 + h3,
[class*=__text] h3 + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + h4, .formatted-text h3 + h4,
[class*=__text] h3 + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + h5, .formatted-text h3 + h5,
[class*=__text] h3 + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + h6, .formatted-text h3 + h6,
[class*=__text] h3 + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + p, .formatted-text h3 + p,
[class*=__text] h3 + p, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + ul, .formatted-text h3 + ul,
[class*=__text] h3 + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + ol, .formatted-text h3 + ol,
[class*=__text] h3 + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + table, .formatted-text h3 + table,
[class*=__text] h3 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + div, .formatted-text h3 + div,
[class*=__text] h3 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + blockquote, .formatted-text h3 + blockquote,
[class*=__text] h3 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + code, .formatted-text h3 + code,
[class*=__text] h3 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + pre, .formatted-text h3 + pre,
[class*=__text] h3 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + h1, .formatted-text h4 + h1,
[class*=__text] h4 + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + h2, .formatted-text h4 + h2,
[class*=__text] h4 + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + h3, .formatted-text h4 + h3,
[class*=__text] h4 + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + h4, .formatted-text h4 + h4,
[class*=__text] h4 + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + h5, .formatted-text h4 + h5,
[class*=__text] h4 + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + h6, .formatted-text h4 + h6,
[class*=__text] h4 + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + p, .formatted-text h4 + p,
[class*=__text] h4 + p, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + ul, .formatted-text h4 + ul,
[class*=__text] h4 + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + ol, .formatted-text h4 + ol,
[class*=__text] h4 + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + table, .formatted-text h4 + table,
[class*=__text] h4 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + div, .formatted-text h4 + div,
[class*=__text] h4 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + blockquote, .formatted-text h4 + blockquote,
[class*=__text] h4 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + code, .formatted-text h4 + code,
[class*=__text] h4 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + pre, .formatted-text h4 + pre,
[class*=__text] h4 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + h1, .formatted-text h5 + h1,
[class*=__text] h5 + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + h2, .formatted-text h5 + h2,
[class*=__text] h5 + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + h3, .formatted-text h5 + h3,
[class*=__text] h5 + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + h4, .formatted-text h5 + h4,
[class*=__text] h5 + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + h5, .formatted-text h5 + h5,
[class*=__text] h5 + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + h6, .formatted-text h5 + h6,
[class*=__text] h5 + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + p, .formatted-text h5 + p,
[class*=__text] h5 + p, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + ul, .formatted-text h5 + ul,
[class*=__text] h5 + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + ol, .formatted-text h5 + ol,
[class*=__text] h5 + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + table, .formatted-text h5 + table,
[class*=__text] h5 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + div, .formatted-text h5 + div,
[class*=__text] h5 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + blockquote, .formatted-text h5 + blockquote,
[class*=__text] h5 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + code, .formatted-text h5 + code,
[class*=__text] h5 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + pre, .formatted-text h5 + pre,
[class*=__text] h5 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + h1, .formatted-text h6 + h1,
[class*=__text] h6 + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + h2, .formatted-text h6 + h2,
[class*=__text] h6 + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + h3, .formatted-text h6 + h3,
[class*=__text] h6 + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + h4, .formatted-text h6 + h4,
[class*=__text] h6 + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + h5, .formatted-text h6 + h5,
[class*=__text] h6 + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + h6, .formatted-text h6 + h6,
[class*=__text] h6 + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + p, .formatted-text h6 + p,
[class*=__text] h6 + p, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + ul, .formatted-text h6 + ul,
[class*=__text] h6 + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + ol, .formatted-text h6 + ol,
[class*=__text] h6 + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + table, .formatted-text h6 + table,
[class*=__text] h6 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + div, .formatted-text h6 + div,
[class*=__text] h6 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + blockquote, .formatted-text h6 + blockquote,
[class*=__text] h6 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + code, .formatted-text h6 + code,
[class*=__text] h6 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + pre, .formatted-text h6 + pre,
[class*=__text] h6 + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p h1 + div, .formatted-text h1 + div,
[class*=__text] h1 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + table, .formatted-text h1 + table,
[class*=__text] h1 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + blockquote, .formatted-text h1 + blockquote,
[class*=__text] h1 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + code, .formatted-text h1 + code,
[class*=__text] h1 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h1 + pre, .formatted-text h1 + pre,
[class*=__text] h1 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + div, .formatted-text h2 + div,
[class*=__text] h2 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + table, .formatted-text h2 + table,
[class*=__text] h2 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + blockquote, .formatted-text h2 + blockquote,
[class*=__text] h2 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + code, .formatted-text h2 + code,
[class*=__text] h2 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h2 + pre, .formatted-text h2 + pre,
[class*=__text] h2 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + div, .formatted-text h3 + div,
[class*=__text] h3 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + table, .formatted-text h3 + table,
[class*=__text] h3 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + blockquote, .formatted-text h3 + blockquote,
[class*=__text] h3 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + code, .formatted-text h3 + code,
[class*=__text] h3 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h3 + pre, .formatted-text h3 + pre,
[class*=__text] h3 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + div, .formatted-text h4 + div,
[class*=__text] h4 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + table, .formatted-text h4 + table,
[class*=__text] h4 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + blockquote, .formatted-text h4 + blockquote,
[class*=__text] h4 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + code, .formatted-text h4 + code,
[class*=__text] h4 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h4 + pre, .formatted-text h4 + pre,
[class*=__text] h4 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + div, .formatted-text h5 + div,
[class*=__text] h5 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + table, .formatted-text h5 + table,
[class*=__text] h5 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + blockquote, .formatted-text h5 + blockquote,
[class*=__text] h5 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + code, .formatted-text h5 + code,
[class*=__text] h5 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h5 + pre, .formatted-text h5 + pre,
[class*=__text] h5 + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + div, .formatted-text h6 + div,
[class*=__text] h6 + div, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + table, .formatted-text h6 + table,
[class*=__text] h6 + table, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + blockquote, .formatted-text h6 + blockquote,
[class*=__text] h6 + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + code, .formatted-text h6 + code,
[class*=__text] h6 + code, .team--teaser_left .content-wrapper .content-wrapper-inner p h6 + pre, .formatted-text h6 + pre,
[class*=__text] h6 + pre {
  margin-top: 1em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul li, .formatted-text ul li,
[class*=__text] ul li {
  list-style-type: disc;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul li ul li, .formatted-text ul li ul li,
[class*=__text] ul li ul li {
  list-style-type: circle;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul li ul li ul li, .formatted-text ul li ul li ul li,
[class*=__text] ul li ul li ul li {
  list-style-type: square;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ol li, .formatted-text ol li,
[class*=__text] ol li {
  list-style-type: decimal;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul, .formatted-text ul,
[class*=__text] ul, .team--teaser_left .content-wrapper .content-wrapper-inner p ol, .formatted-text ol,
[class*=__text] ol {
  list-style: outside;
  margin: 0 0 1em 2.25em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul li, .formatted-text ul li,
[class*=__text] ul li, .team--teaser_left .content-wrapper .content-wrapper-inner p ol li, .formatted-text ol li,
[class*=__text] ol li {
  line-height: 1.35em;
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul li:first-of-type, .formatted-text ul li:first-of-type,
[class*=__text] ul li:first-of-type, .team--teaser_left .content-wrapper .content-wrapper-inner p ol li:first-of-type, .formatted-text ol li:first-of-type,
[class*=__text] ol li:first-of-type {
  margin-top: 0;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul li ul, .formatted-text ul li ul,
[class*=__text] ul li ul,
.team--teaser_left .content-wrapper .content-wrapper-inner p ul li ol,
.formatted-text ul li ol,
[class*=__text] ul li ol, .team--teaser_left .content-wrapper .content-wrapper-inner p ol li ul, .formatted-text ol li ul,
[class*=__text] ol li ul,
.team--teaser_left .content-wrapper .content-wrapper-inner p ol li ol,
.formatted-text ol li ol,
[class*=__text] ol li ol {
  margin-top: 0.75em;
  margin-bottom: 0;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p ul + p, .formatted-text ul + p,
[class*=__text] ul + p, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + h1, .formatted-text ul + h1,
[class*=__text] ul + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + h2, .formatted-text ul + h2,
[class*=__text] ul + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + h3, .formatted-text ul + h3,
[class*=__text] ul + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + h4, .formatted-text ul + h4,
[class*=__text] ul + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + h5, .formatted-text ul + h5,
[class*=__text] ul + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + h6, .formatted-text ul + h6,
[class*=__text] ul + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + table, .formatted-text ul + table,
[class*=__text] ul + table, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + div, .formatted-text ul + div,
[class*=__text] ul + div, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + blockquote, .formatted-text ul + blockquote,
[class*=__text] ul + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + code, .formatted-text ul + code,
[class*=__text] ul + code, .team--teaser_left .content-wrapper .content-wrapper-inner p ul + pre, .formatted-text ul + pre,
[class*=__text] ul + pre, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + p, .formatted-text ol + p,
[class*=__text] ol + p, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + h1, .formatted-text ol + h1,
[class*=__text] ol + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + h2, .formatted-text ol + h2,
[class*=__text] ol + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + h3, .formatted-text ol + h3,
[class*=__text] ol + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + h4, .formatted-text ol + h4,
[class*=__text] ol + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + h5, .formatted-text ol + h5,
[class*=__text] ol + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + h6, .formatted-text ol + h6,
[class*=__text] ol + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + table, .formatted-text ol + table,
[class*=__text] ol + table, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + div, .formatted-text ol + div,
[class*=__text] ol + div, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + blockquote, .formatted-text ol + blockquote,
[class*=__text] ol + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + code, .formatted-text ol + code,
[class*=__text] ol + code, .team--teaser_left .content-wrapper .content-wrapper-inner p ol + pre, .formatted-text ol + pre,
[class*=__text] ol + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p div > *:first-child, .formatted-text div > *:first-child,
[class*=__text] div > *:first-child {
  margin-top: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p div + p, .formatted-text div + p,
[class*=__text] div + p, .team--teaser_left .content-wrapper .content-wrapper-inner p div + ul, .formatted-text div + ul,
[class*=__text] div + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p div + ol, .formatted-text div + ol,
[class*=__text] div + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p div + h1, .formatted-text div + h1,
[class*=__text] div + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p div + h2, .formatted-text div + h2,
[class*=__text] div + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p div + h3, .formatted-text div + h3,
[class*=__text] div + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p div + h4, .formatted-text div + h4,
[class*=__text] div + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p div + h5, .formatted-text div + h5,
[class*=__text] div + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p div + h6, .formatted-text div + h6,
[class*=__text] div + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p div + table, .formatted-text div + table,
[class*=__text] div + table, .team--teaser_left .content-wrapper .content-wrapper-inner p div + div, .formatted-text div + div,
[class*=__text] div + div, .team--teaser_left .content-wrapper .content-wrapper-inner p div + blockquote, .formatted-text div + blockquote,
[class*=__text] div + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p div + code, .formatted-text div + code,
[class*=__text] div + code, .team--teaser_left .content-wrapper .content-wrapper-inner p div + pre, .formatted-text div + pre,
[class*=__text] div + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table > *:first-child, .formatted-text table > *:first-child,
[class*=__text] table > *:first-child {
  margin-top: 0;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table + p, .formatted-text table + p,
[class*=__text] table + p, .team--teaser_left .content-wrapper .content-wrapper-inner p table + ul, .formatted-text table + ul,
[class*=__text] table + ul, .team--teaser_left .content-wrapper .content-wrapper-inner p table + ol, .formatted-text table + ol,
[class*=__text] table + ol, .team--teaser_left .content-wrapper .content-wrapper-inner p table + h1, .formatted-text table + h1,
[class*=__text] table + h1, .team--teaser_left .content-wrapper .content-wrapper-inner p table + h2, .formatted-text table + h2,
[class*=__text] table + h2, .team--teaser_left .content-wrapper .content-wrapper-inner p table + h3, .formatted-text table + h3,
[class*=__text] table + h3, .team--teaser_left .content-wrapper .content-wrapper-inner p table + h4, .formatted-text table + h4,
[class*=__text] table + h4, .team--teaser_left .content-wrapper .content-wrapper-inner p table + h5, .formatted-text table + h5,
[class*=__text] table + h5, .team--teaser_left .content-wrapper .content-wrapper-inner p table + h6, .formatted-text table + h6,
[class*=__text] table + h6, .team--teaser_left .content-wrapper .content-wrapper-inner p table + table, .formatted-text table + table,
[class*=__text] table + table, .team--teaser_left .content-wrapper .content-wrapper-inner p table + div, .formatted-text table + div,
[class*=__text] table + div, .team--teaser_left .content-wrapper .content-wrapper-inner p table + blockquote, .formatted-text table + blockquote,
[class*=__text] table + blockquote, .team--teaser_left .content-wrapper .content-wrapper-inner p table + code, .formatted-text table + code,
[class*=__text] table + code, .team--teaser_left .content-wrapper .content-wrapper-inner p table + pre, .formatted-text table + pre,
[class*=__text] table + pre {
  margin-top: 0.75em;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p hr, .formatted-text hr,
[class*=__text] hr {
  outline: 0;
  border: 0;
  display: block;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 0;
  margin-right: 0;
  background: #e4ebf1;
}
@media screen and (max-width: 768px) {
  .team--teaser_left .content-wrapper .content-wrapper-inner p hr, .formatted-text hr,
[class*=__text] hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.team--teaser_left .content-wrapper .content-wrapper-inner p blockquote, .formatted-text blockquote,
[class*=__text] blockquote {
  position: relative;
  min-height: 2.821875rem;
  padding-left: calc(2.8125rem + 20px);
  font-style: italic;
  color: #0154a2;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p blockquote:before, .formatted-text blockquote:before,
[class*=__text] blockquote:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f10d";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-size: 2.8125rem;
  position: absolute;
  left: 0;
  top: 0.15rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table, .formatted-text table,
[class*=__text] table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table thead th, .formatted-text table thead th,
[class*=__text] table thead th {
  border-bottom: 2px solid #01a4a9 !important;
  border-right: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table thead + tbody tr th:first-child, .formatted-text table thead + tbody tr th:first-child,
[class*=__text] table thead + tbody tr th:first-child {
  border-right: 0;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table tfoot th, .formatted-text table tfoot th,
[class*=__text] table tfoot th {
  border-top: 2px solid #01a4a9;
  border-right: 0 !important;
  border-bottom: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table td, .formatted-text table td,
[class*=__text] table td {
  border-bottom: 1px solid #01a4a9;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table th, .formatted-text table th,
[class*=__text] table th,
.team--teaser_left .content-wrapper .content-wrapper-inner p table td,
.formatted-text table td,
[class*=__text] table td {
  text-align: left;
  padding: 0.5rem 0.75rem;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table th > *:first-child, .formatted-text table th > *:first-child,
[class*=__text] table th > *:first-child,
.team--teaser_left .content-wrapper .content-wrapper-inner p table td > *:first-child,
.formatted-text table td > *:first-child,
[class*=__text] table td > *:first-child {
  margin-top: 0 !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p table tr th:first-child, .formatted-text table tr th:first-child,
[class*=__text] table tr th:first-child {
  border-right: 2px solid #01a4a9;
  border-bottom: 1px solid #01a4a9;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .table--two-heads table tbody tr th:first-child, .formatted-text .table--two-heads table tbody tr th:first-child,
[class*=__text] .table--two-heads table tbody tr th:first-child {
  border-right: 2px solid #01a4a9 !important;
  border-bottom: 1px solid #01a4a9;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .table--striped tbody tr:nth-child(odd), .formatted-text .table--striped tbody tr:nth-child(odd),
[class*=__text] .table--striped tbody tr:nth-child(odd) {
  background-color: #e4ebf1;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .table--responsive, .formatted-text .table--responsive,
[class*=__text] .table--responsive {
  overflow-x: auto;
}
@media screen and (max-width: 768px) {
  .team--teaser_left .content-wrapper .content-wrapper-inner p .table--responsive, .formatted-text .table--responsive,
[class*=__text] .table--responsive {
    width: 100%;
  }
  .team--teaser_left .content-wrapper .content-wrapper-inner p .table--responsive th, .formatted-text .table--responsive th,
[class*=__text] .table--responsive th,
.team--teaser_left .content-wrapper .content-wrapper-inner p .table--responsive td,
.formatted-text .table--responsive td,
[class*=__text] .table--responsive td {
    white-space: nowrap;
  }
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--1_1, .formatted-text .responsive-iframe-container .responsive-iframe--1_1,
[class*=__text] .responsive-iframe-container .responsive-iframe--1_1 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 100% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--1_1 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--1_1 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--1_1 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--4_3, .formatted-text .responsive-iframe-container .responsive-iframe--4_3,
[class*=__text] .responsive-iframe-container .responsive-iframe--4_3 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 75% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--4_3 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--4_3 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--4_3 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--3_2, .formatted-text .responsive-iframe-container .responsive-iframe--3_2,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_2 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 66.6666666667% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--3_2 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--3_2 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_2 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--5_3, .formatted-text .responsive-iframe-container .responsive-iframe--5_3,
[class*=__text] .responsive-iframe-container .responsive-iframe--5_3 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 60% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--5_3 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--5_3 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--5_3 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--16_9, .formatted-text .responsive-iframe-container .responsive-iframe--16_9,
[class*=__text] .responsive-iframe-container .responsive-iframe--16_9 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 56.25% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--16_9 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--16_9 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--16_9 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--3_1, .formatted-text .responsive-iframe-container .responsive-iframe--3_1,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_1 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 33.3333333333% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container .responsive-iframe--3_1 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--3_1 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_1 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p .responsive-iframe-container iframe, .formatted-text .responsive-iframe-container iframe,
[class*=__text] .responsive-iframe-container iframe {
  width: 100%;
  height: 100%;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p a:not(.button):not(.btn):not(.text--button):not(.text--button-2), .formatted-text a:not(.button):not(.btn):not(.text--button):not(.text--button-2),
[class*=__text] a:not(.button):not(.btn):not(.text--button):not(.text--button-2) {
  color: #01a4a9;
  text-decoration: underline;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover, .formatted-text a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover,
[class*=__text] a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover {
  background: #01a4a9;
  color: #ffffff;
  text-decoration: none;
}

.service-page__contact-person .team-member__info .team-member__title:after, html .userform .FormHeading:after, .homepage--teaser-boxes .teaser-box .content h3:after, .team-members .team-member__title:after,
.search-results__list--TeamMember .search-results__list-items .team-member__title:after, .service-preview__text h2:after, .reference-preview__right .reference-preview__text h3:after, .reference-detail__bottom .contact-form-block span:after, .footer--inner-wrapper_top .footer--box span:after, .homepage--carrer_block h3:after {
  content: "";
  height: 2px;
  width: 80px;
  display: block;
  background: #01a4a9;
  margin-left: -15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (max-width: 1170px) {
  .service-page__contact-person .team-member__info .team-member__title:after, html .userform .FormHeading:after, .homepage--teaser-boxes .teaser-box .content h3:after, .team-members .team-member__title:after,
.search-results__list--TeamMember .search-results__list-items .team-member__title:after, .service-preview__text h2:after, .reference-preview__right .reference-preview__text h3:after, .reference-detail__bottom .contact-form-block span:after, .footer--inner-wrapper_top .footer--box span:after, .homepage--carrer_block h3:after {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .service-page__contact-person .team-member__info .team-member__title:after, html .userform .FormHeading:after, .homepage--teaser-boxes .teaser-box .content h3:after, .team-members .team-member__title:after,
.search-results__list--TeamMember .search-results__list-items .team-member__title:after, .service-preview__text h2:after, .reference-preview__right .reference-preview__text h3:after, .reference-detail__bottom .contact-form-block span:after, .footer--inner-wrapper_top .footer--box span:after, .homepage--carrer_block h3:after {
    width: 60px;
  }
}

.bath-showroom__top .right .bath-showroom__quote:after, .team--teaser_left .content-wrapper .content-wrapper-inner h2:after, .page-title h1:after {
  content: "";
  height: 2px;
  width: 160px;
  display: block;
  background: #01a4a9;
  margin-left: -15px;
  margin-top: 20px;
}
@media screen and (max-width: 1170px) {
  .bath-showroom__top .right .bath-showroom__quote:after, .team--teaser_left .content-wrapper .content-wrapper-inner h2:after, .page-title h1:after {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .bath-showroom__top .right .bath-showroom__quote:after, .team--teaser_left .content-wrapper .content-wrapper-inner h2:after, .page-title h1:after {
    margin-top: 10px;
    width: 80px;
  }
}

html {
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  html {
    font-size: 16px-2px;
  }
}

body {
  font-family: "Muli", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #003465;
  text-size-adjust: none;
}

strong, b, th {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

small {
  font-size: 0.75rem;
}

h1, h2, h3, h4, h5, h6,
strong, p, b, em, i, span, a, small, th, td, li {
  line-height: 1.35em;
}

i.fa,
i.fal,
i.far,
i.fab {
  display: inline-block;
  min-width: 1em;
  text-align: center;
  font-style: normal;
}

a {
  color: #003465;
  text-decoration: none;
}
a:hover {
  color: #01a4a9;
}

::selection {
  color: #ffffff;
  background: #01a4a9;
}

::-moz-selection {
  color: #ffffff;
  background: #01a4a9;
}

hr {
  border: none;
  height: 1px;
  background: #01a4a9;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

em, i {
  font-style: italic;
}

.system-message + *, .message:not([style*="display: none"]) + * {
  margin-top: 40px !important;
}
@media screen and (max-width: 768px) {
  .system-message + *, .message:not([style*="display: none"]) + * {
    margin-top: 20px !important;
  }
}
.system-message + .system-message, .message:not([style*="display: none"]) + .system-message, .system-message + .message:not([style*="display: none"]), .message:not([style*="display: none"]) + .message:not([style*="display: none"]) {
  margin-top: 6.6666666667px !important;
}
.system-message, .message:not([style*="display: none"]), .system-message--neutral {
  cursor: pointer;
  position: relative;
  border-left: 5px solid #01a4a9;
  padding: 20px 40px 20px 20px;
  background: rgba(1, 164, 169, 0.1);
}
.system-message:after, .message:not([style*="display: none"]):after, .system-message--neutral:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.5;
  transition: all 0.2s linear;
}
.system-message:hover:after, .message:hover:not([style*="display: none"]):after, .system-message--neutral:hover:after {
  opacity: 1;
}
.system-message--good, .message:not([style*="display: none"]).good, .message:not([style*="display: none"]).success {
  border-color: #9edd3c;
  background: rgba(158, 221, 60, 0.1);
}
.system-message--warning, .message:not([style*="display: none"]).warning {
  border-color: #eabe17;
  background: rgba(234, 190, 23, 0.1);
}
.system-message--bad, .message:not([style*="display: none"]).bad, .message:not([style*="display: none"]).error, .message:not([style*="display: none"]).required {
  border-color: #dd3c3c;
  background: rgba(221, 60, 60, 0.1);
}
.system-message--permanent, .message:not([style*="display: none"]) {
  cursor: default;
}
.system-message--permanent:after, .message:not([style*="display: none"]):after {
  display: none;
}

.dist-right {
  margin-right: 0.25em;
}

.dist-left {
  margin-left: 0.25em;
}

.dist-top {
  margin-top: 0.25em;
}

.dist-bottom {
  margin-bottom: 0.25em;
}

br {
  line-height: 1;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

textarea {
  resize: vertical;
}

.global-sc-dist-right {
  margin-right: 0.5em;
}

.global-sc-dist-left {
  margin-left: 0.5em;
}

.global-sc-dist-top {
  margin-top: 0.5em;
}

.global-sc-dist-bottom {
  margin-bottom: 0.5em;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

img {
  vertical-align: top;
}

*:focus {
  outline: 0;
}

main,
.main {
  display: block;
}

html {
  background: #001c37;
}

body {
  background: #ffffff;
}

.header-wrapper {
  position: relative;
}

.outer-wrapper {
  margin-top: 100px;
}

.page--no-banner .outer-wrapper {
  margin-top: 160px;
}
@media screen and (max-width: 1170px) {
  .page--no-banner .outer-wrapper {
    margin-top: 140px;
  }
}

.modular-content .module {
  position: relative;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .modular-content .module {
    margin-bottom: 20px;
  }
}
.modular-content .module:hover .module__editlink {
  opacity: 0.625;
}
.modular-content .module__editlink {
  opacity: 0.25;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 1em;
  color: #ffffff;
  border-radius: 5px;
  background: #01a4a9;
  transition: opacity 0.2s linear, background 0.2s linear;
}
.modular-content .module__editlink i {
  margin-right: 0.5em;
}
.modular-content .module__editlink:hover {
  opacity: 1 !important;
}
.modular-content .module:last-of-type {
  margin-bottom: 0;
}
.modular-content .module--separator-top {
  padding-top: 40px;
  border-top: 1px solid #01a4a9;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-top {
    padding-top: 20px;
  }
}
.modular-content .module--separator-top .module__editlink {
  top: 40px;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-top .module__editlink {
    top: 20px;
  }
}
.modular-content .module--separator-bottom {
  padding-bottom: 40px;
  border-bottom: 1px solid #01a4a9;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-bottom {
    padding-bottom: 20px;
  }
}
.modular-content .module:first-of-type.module--separator-top {
  padding-top: 0;
  border-top: 0;
}
.modular-content .module:last-of-type {
  margin-bottom: 0;
}
.modular-content .module:last-of-type.module--separator-bottom {
  padding-bottom: 0;
  border-bottom: 0;
}
.modular-content .module__title {
  margin-bottom: 20px;
  font-family: "Saira", sans-serif;
}
@media screen and (max-width: 768px) {
  .modular-content .module__title {
    margin-bottom: 10px;
  }
}
.modular-content .module__title strong {
  font-weight: 400;
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  .modular-content .module__title strong {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
}
.modular-content .module__title h3 {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .modular-content .module__title h3 {
    font-size: 2rem;
  }
}
.modular-content .modular-content-columns {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .modular-content .modular-content-columns {
    margin-bottom: 20px;
  }
}
.modular-content .modular-content-columns .modular-content-column {
  opacity: 1;
}
.modular-content > *:last-child {
  margin-bottom: 0;
}
.modular-content .module--accordion .accordion__item {
  border: 1px solid #e4ebf1;
  padding: 2px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.modular-content .module--accordion .accordion__item:last-of-type {
  margin-bottom: 0;
}
.modular-content .module--accordion .accordion__item--open .accordion__content {
  display: block;
}
.modular-content .module--accordion .accordion__item--open .accordion__title {
  background: #e4ebf1;
  border-radius: 4px;
}
.modular-content .module--accordion .accordion__title {
  cursor: pointer;
  padding: 15px;
}
.modular-content .module--accordion .accordion__title:hover {
  background: #e4ebf1;
}
.modular-content .module--accordion .accordion__title:hover i {
  color: #018b90;
}
.modular-content .module--accordion .accordion__title strong {
  display: block;
  float: left;
  margin-right: 10px;
  width: calc(100% - 10px - 1rem);
}
.modular-content .module--accordion .accordion__title i {
  display: block;
  float: right;
  color: #01a4a9;
  font-size: 1rem;
  width: 1rem;
  text-align: center;
}
.modular-content .module--accordion .accordion__content {
  padding: 20px;
  display: none;
}
.modular-content .modular-content-column {
  width: 48.7179487179%;
  margin-right: 2.5641025641%;
  float: left;
  opacity: 0;
}
@media screen and (max-width: 1020px) {
  .modular-content .modular-content-column {
    width: 100%;
    margin-right: 0;
    float: none;
  }
  .modular-content .modular-content-column + .modular-content-column {
    margin-top: 40px;
  }
}
.modular-content .modular-content-column + .modular-content-column {
  margin-right: 0;
}
.modular-content .modular-content-column .module__title {
  margin-bottom: calc(40px - 0.5rem);
}
.modular-content .modular-content-column .module__inner {
  width: 100% !important;
}
.modular-content .modular-content-column .module--bg-dark, .modular-content .modular-content-column .module--bg-light {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: #ffffff !important;
}
.modular-content .modular-content-column .module--bg-dark .module__title h3, .modular-content .modular-content-column .module--bg-light .module__title h3 {
  color: #003465;
}
.modular-content .modular-content-column .module--bg-dark .module__title strong, .modular-content .modular-content-column .module--bg-light .module__title strong {
  color: #0154a2;
}
.modular-content .modular-content-column .module--bg-dark .textimage__title,
.modular-content .modular-content-column .module--bg-dark .formatted-text, .modular-content .modular-content-column .module--bg-light .textimage__title,
.modular-content .modular-content-column .module--bg-light .formatted-text {
  color: #003465;
}
.modular-content .modular-content-column .module--bg-dark .textimage__sub-title,
.modular-content .modular-content-column .module--bg-dark .text--discreet, .modular-content .modular-content-column .module--bg-light .textimage__sub-title,
.modular-content .modular-content-column .module--bg-light .text--discreet {
  color: #0154a2 !important;
}
.modular-content .modular-content-column .module--bg-dark.module--opinions .opinion, .modular-content .modular-content-column .module--bg-light.module--opinions .opinion {
  background: #ffffff;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item {
  border-color: #01a4a9;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title {
  background: #e4ebf1;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title i, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title i {
  color: #01a4a9;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title,
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__title, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title,
.modular-content .modular-content-column .module--bg-light.module--accordion .accordion__title {
  color: #003465;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title:hover,
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__title:hover, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title:hover,
.modular-content .modular-content-column .module--bg-light.module--accordion .accordion__title:hover {
  background: #e4ebf1;
}
.modular-content .modular-content-column .module--bg-dark .text--background-discreet, .modular-content .modular-content-column .module--bg-light .text--background-discreet {
  color: #003465;
}
.modular-content .modular-content-column .module--bg-dark.module--grid .grid__item, .modular-content .modular-content-column .module--bg-light.module--grid .grid__item {
  background: #e4ebf1;
}
.modular-content .modular-content-column .module--bg-dark.module--grid .grid__content,
.modular-content .modular-content-column .module--bg-dark.module--grid .grid__title, .modular-content .modular-content-column .module--bg-light.module--grid .grid__content,
.modular-content .modular-content-column .module--bg-light.module--grid .grid__title {
  color: #003465;
}
.modular-content .modular-content-column .module--bg-dark.module--downloads ul li, .modular-content .modular-content-column .module--bg-light.module--downloads ul li {
  border-bottom-color: #01a4a9;
}
.modular-content .modular-content-column .module--bg-dark.module--downloads ul li > a, .modular-content .modular-content-column .module--bg-light.module--downloads ul li > a {
  color: #003465;
}
.modular-content .modular-content-column .module--bg-dark.module--downloads ul li > a i, .modular-content .modular-content-column .module--bg-light.module--downloads ul li > a i {
  color: #01a4a9;
}
.modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form, .modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form *, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form * {
  color: #003465;
}
.modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form.cr_button, .modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form *.cr_button, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form.cr_button, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form *.cr_button {
  color: #ffffff !important;
}
.modular-content .modular-content-column .module:last-of-type.module--bg-light, .modular-content .modular-content-column .module:last-of-type.module--bg-dark {
  margin-bottom: 0 !important;
}
.modular-content .modular-content-column .module--bg-light + .module--bg-light, .modular-content .modular-content-column .module--bg-light + .module--bg-dark, .modular-content .modular-content-column .module--bg-dark + .module--bg-light, .modular-content .modular-content-column .module--bg-dark + .module--bg-dark {
  margin-top: 0 !important;
}
.modular-content .module--downloads ul {
  margin: 0;
  padding: 0;
}
.modular-content .module--downloads ul li {
  list-style: none !important;
  display: block;
  border-bottom: 1px dashed #e4ebf1;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.modular-content .module--downloads ul li:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.modular-content .module--downloads ul li a {
  display: block;
  width: calc(100% - 100px);
  float: left;
  color: #003465;
  text-decoration: none;
}
.modular-content .module--downloads ul li a:hover {
  color: #01a4a9;
}
.modular-content .module--downloads ul li a i {
  margin-right: 0.5em;
  font-size: 1.5rem;
  color: #01a4a9;
}
.modular-content .module--downloads ul li span {
  display: block;
  width: 100px;
  text-align: right;
  float: right;
  color: #0154a2;
}
.modular-content .module--gallery .gallery {
  margin: 0;
  padding: 0;
}
.modular-content .module--gallery .gallery li {
  display: block;
  float: left;
}
.modular-content .module--gallery .gallery li a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  border-radius: 4px;
}
.modular-content .module--gallery .gallery li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #01a4a9;
  background: rgba(0, 0, 0, 0.85);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 2rem;
  width: 100%;
  transition: all 0.2s linear;
}
.modular-content .module--gallery .gallery li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(1, 164, 169, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.modular-content .module--gallery .gallery li a:hover:before, .modular-content .module--gallery .gallery li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.modular-content .module--gallery .gallery li a:hover:after {
  left: 4px;
}
.modular-content .module--gallery .gallery li a img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 360px) {
  .modular-content .module--gallery .gallery--ipr-2 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 361px) {
  .modular-content .module--gallery .gallery--ipr-2 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (max-width: 360px) {
  .modular-content .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 361px) and (max-width: 768px) {
  .modular-content .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 360px) {
  .modular-content .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 361px) and (max-width: 768px) {
  .modular-content .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (max-width: 360px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 361px) and (max-width: 480px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 1036px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 14.5299145299%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(6n+7) {
    clear: both;
  }
}
.modular-content .module--grid .grid__item {
  display: block;
  float: left;
  background: #e4ebf1;
  border-radius: 4px;
}
.modular-content .module--grid .grid__header--icon {
  margin-top: -15px;
}
.modular-content .module--grid .grid__header {
  text-align: center;
  margin-bottom: 20px;
}
.modular-content .module--grid .grid__header img {
  display: inline-block;
  width: 100%;
  height: auto;
  border-radius: 4px 4px 0 0;
}
.modular-content .module--grid .grid__header i {
  font-size: 1.5rem;
  color: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background: linear-gradient(45deg, #01a4a9 0%, #0154a2 100%);
}
.modular-content .module--grid .grid__header i:before {
  padding-top: 12px;
  display: block;
}
.modular-content .module--grid .grid__header a {
  color: #003465;
}
.modular-content .module--grid .grid__header a:hover {
  color: #01a4a9;
}
.modular-content .module--grid .grid__title {
  margin-bottom: 20px;
  padding: 0 30px;
}
.modular-content .module--grid .grid__title h4 {
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 400;
}
.modular-content .module--grid .grid__content {
  padding: 0 30px 30px 30px;
}
.modular-content .module--grid .grid__link {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
}
.modular-content .module--grid .grid--align-center {
  text-align: center;
}
@media screen and (max-width: 480px) {
  .modular-content .module--grid .grid--ipr-2 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  .modular-content .module--grid .grid--ipr-2 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (max-width: 480px) {
  .modular-content .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modular-content .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 480px) {
  .modular-content .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modular-content .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(4n+5) {
    clear: both;
  }
}
.modular-content .module--headline h2 {
  font-size: 2.8125rem;
}
.modular-content .module--textimage .module__inner .textimage__image a,
.modular-content .module--textimage .module__inner a.textimage__image {
  display: block;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox],
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox] {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:after,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #01a4a9;
  background: rgba(0, 0, 0, 0.85);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 2rem;
  width: 100%;
  transition: all 0.2s linear;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:before,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(1, 164, 169, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:hover:before, .modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:hover:after,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:hover:before,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:hover:after,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:hover:after {
  left: 4px;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]),
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]) {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):after,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f061";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #01a4a9;
  background: rgba(0, 0, 0, 0.85);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 2rem;
  width: 100%;
  transition: all 0.2s linear;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):before,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(1, 164, 169, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:before, .modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:after,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:before,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:after,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:after {
  left: 4px;
}
.modular-content .module--textimage .module__inner .textimage__image a img,
.modular-content .module--textimage .module__inner a.textimage__image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.modular-content .module--textimage .textimage--image-top .textimage__image {
  margin-bottom: 40px;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .modular-content .module--textimage .textimage--image-top .textimage__image {
    margin-bottom: 20px;
  }
}
.modular-content .module--textimage .textimage--image-bottom .textimage__image {
  margin-top: 40px;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .modular-content .module--textimage .textimage--image-bottom .textimage__image {
    margin-top: 20px;
  }
}
.modular-content .module--textimage .textimage .formatted-text span {
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
}
.modular-content .module--textimage .textimage .formatted-text .textimage__title {
  margin-top: 0 !important;
  font-weight: 600;
  text-transform: uppercase;
}
.modular-content .module--textimage .textimage .formatted-text .textimage__title:after {
  margin-top: 10px;
}
.modular-content .module--textimage .textimage--image-left .textimage__image, .modular-content .module--textimage .textimage--image-right .textimage__image {
  border-radius: 4px;
  width: 23.0769230769%;
}
.modular-content .module--textimage .textimage--image-left .textimage__image img, .modular-content .module--textimage .textimage--image-right .textimage__image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.modular-content .module--textimage .textimage--image-left .formatted-text, .modular-content .module--textimage .textimage--image-right .formatted-text {
  width: 74.358974359%;
  float: left;
}
@media screen and (max-width: 480px) {
  .modular-content .module--textimage .textimage--image-left .textimage__image, .modular-content .module--textimage .textimage--image-right .textimage__image {
    float: none !important;
    margin: 0;
    width: 100%;
  }
  .modular-content .module--textimage .textimage--image-left .textimage__image + .formatted-text, .modular-content .module--textimage .textimage--image-right .textimage__image + .formatted-text {
    margin-top: 20px !important;
  }
  .modular-content .module--textimage .textimage--image-left .formatted-text, .modular-content .module--textimage .textimage--image-right .formatted-text {
    float: none;
    margin: 0;
    width: 100%;
  }
  .modular-content .module--textimage .textimage--image-left .formatted-text + .textimage__image, .modular-content .module--textimage .textimage--image-right .formatted-text + .textimage__image {
    margin-top: 20px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modular-content .module--textimage .textimage--image-left .textimage__image, .modular-content .module--textimage .textimage--image-right .textimage__image {
    width: 48.7179487179%;
  }
  .modular-content .module--textimage .textimage--image-left .formatted-text, .modular-content .module--textimage .textimage--image-right .formatted-text {
    width: 48.7179487179%;
  }
}
.modular-content .module--textimage .textimage--image-left .textimage__image {
  float: left;
  margin-right: 2.5641025641%;
}
.modular-content .module--textimage .textimage--image-right .textimage__image {
  float: right;
  margin-left: 2.5641025641%;
}
.modular-content .module--textimage .textimage--text-floating .formatted-text {
  width: 100%;
}
.modular-content .module--textimage .textimage--text-floating .formatted-text p {
  overflow: visible;
}
.modular-content .module--textimage .textimage--text-floating .textimage__image {
  display: block;
  float: left;
  margin-bottom: 10px;
}
@media screen and (max-width: 480px) {
  .modular-content .module--textimage .textimage--text-floating .textimage__image {
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
}
.modular-content .module--textimage .textimage--text-floating.textimage--image-right .textimage__image {
  float: right;
}

.address-bar {
  position: relative;
  z-index: 100;
  margin-top: -100px;
}
@media screen and (max-width: 1170px) {
  .address-bar {
    margin-top: -80px;
    width: 100%;
  }
}
@media screen and (max-width: 580px) {
  .address-bar {
    margin-top: -44px;
  }
}
.address-bar--inner .address-bar--slider-buttons {
  width: 100%;
  text-align: right;
}
.address-bar--inner .address-bar--slider-buttons i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-weight: 300;
  font-size: 1.5rem;
  cursor: pointer;
}
@media screen and (max-width: 1170px) {
  .address-bar--inner .address-bar--slider-buttons i {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
@media screen and (max-width: 580px) {
  .address-bar--inner .address-bar--slider-buttons i {
    width: 42px;
    height: 42px;
    line-height: 42px;
  }
}
.address-bar--inner .address-bar--slider-buttons .fa-long-arrow-left {
  background-color: rgba(228, 235, 241, 0.65);
  border-radius: 4px 0 0 0;
  transition: 0.2s;
}
.address-bar--inner .address-bar--slider-buttons .fa-long-arrow-left:hover {
  background: #e4ebf1;
}
@media screen and (max-width: 1170px) {
  .address-bar--inner .address-bar--slider-buttons .fa-long-arrow-left {
    border-radius: 0;
  }
}
.address-bar--inner .address-bar--slider-buttons .fa-long-arrow-right {
  background-color: #e4ebf1;
  border-radius: 0 4px 0 0;
}
@media screen and (max-width: 1170px) {
  .address-bar--inner .address-bar--slider-buttons .fa-long-arrow-right {
    border-radius: 0;
  }
}
.address-bar--inner .address-bar--inner-wrapper {
  background: #e4ebf1;
  border-radius: 4px 0 4px 4px;
  padding: 0 30px;
  height: 80px;
  line-height: 80px;
  width: 100%;
}
@media screen and (max-width: 1170px) {
  .address-bar--inner .address-bar--inner-wrapper {
    border-radius: 0;
    line-height: 60px;
    height: 60px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 580px) {
  .address-bar--inner .address-bar--inner-wrapper {
    display: none;
  }
}
.address-bar--inner .address-bar--inner-wrapper span {
  border-right: 1px solid #ffffff;
  display: inline-block;
  margin-right: calc(40px - 2px);
  padding-right: calc(40px - 2px);
  font-size: 1rem;
  margin-left: -0.25em;
}
.address-bar--inner .address-bar--inner-wrapper span:first-of-type {
  margin-left: 0;
}
@media screen and (max-width: 1170px) {
  .address-bar--inner .address-bar--inner-wrapper span {
    margin-right: calc((40px / 2) - 2px);
    padding-right: calc((40px / 2) - 2px);
  }
}
@media screen and (max-width: 1036px) {
  .address-bar--inner .address-bar--inner-wrapper span {
    border-right: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 980px) {
  .address-bar--inner .address-bar--inner-wrapper span:first-of-type {
    display: none;
  }
}
.address-bar--inner .address-bar--inner-wrapper span:last-of-type {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.address-bar--inner .address-bar--inner-wrapper span a:hover {
  text-decoration: underline;
}
.address-bar--inner .address-bar--inner-wrapper .phone {
  padding-left: 1.5em;
}
.address-bar--inner .address-bar--inner-wrapper .phone:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f095";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.address-bar--inner .address-bar--inner-wrapper .fax {
  padding-left: 1.5em;
}
.address-bar--inner .address-bar--inner-wrapper .fax:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f02f";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.address-bar--inner .address-bar--inner-wrapper .email {
  padding-left: 1.5em;
}
.address-bar--inner .address-bar--inner-wrapper .email:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f1d8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}

html .basic-holder .basic-holder__text + .basic-holder__children {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #e4ebf1;
}
@media screen and (max-width: 768px) {
  html .basic-holder .basic-holder__text + .basic-holder__children {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  html .basic-holder .basic-holder__text + .basic-holder__children {
    padding-top: 20px;
  }
}
html .basic-holder__child {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child {
    margin-top: 20px;
  }
}
html .basic-holder__child:first-of-type {
  margin-top: 0;
}
html .basic-holder__child > h3 {
  display: block;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child > h3 {
    margin-bottom: 10px;
  }
}
html .basic-holder__child > h3 a {
  color: #003465;
}
html .basic-holder__child > h3 a:hover {
  color: #01a4a9;
}
html .basic-holder__child > p {
  margin: 0;
}
html .basic-holder__grand-children {
  margin: 0 !important;
  margin-top: 10px;
}
@media screen and (max-width: 480px) {
  html .basic-holder__grand-children {
    margin-top: 5px;
  }
}
html .basic-holder__grand-children li {
  display: block;
}
@media screen and (min-width: 769px) {
  html .basic-holder__grand-children li {
    float: left;
    width: calc(50% - 10px);
  }
  html .basic-holder__grand-children li:nth-of-type(2n+2) {
    margin-left: 20px;
  }
  html .basic-holder__grand-children li:nth-of-type(2n+3) {
    clear: both;
  }
}
html .basic-holder__pagination {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__pagination {
    margin-top: 20px;
  }
}

#calendar .fc-event, #calendar .fc-event-dot {
  background: #01a4a9;
  border-color: #01a4a9;
}
#calendar .fc-time,
#calendar .fc-title {
  font-size: 0.75rem;
  padding: 6.6666666667px;
}
#calendar .fc-time {
  font-weight: 700;
  padding-bottom: 0 !important;
}
#calendar .fc-today {
  background: none !important;
}
#calendar .fc-nonbusiness {
  background: #a0a0a0;
  cursor: not-allowed;
}
#calendar .fc-toolbar .fc-left .fc-prev-button,
#calendar .fc-toolbar .fc-left .fc-next-button {
  background: #003465;
  border: none;
}
#calendar .fc-toolbar .fc-left .fc-today-button {
  border: none;
  background: #003465;
}
#calendar .fc-toolbar .fc-left .fc-button:disabled {
  background: #90adc5;
  opacity: 1 !important;
}

.homepage--carrer_block {
  float: right;
  background: #e4ebf1;
  padding: 30px 40px 50px 40px;
  border-radius: 4px;
  width: 23.0769230769%;
  position: relative;
}
@media screen and (max-width: 1036px) {
  .homepage--carrer_block {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .homepage--carrer_block {
    padding: 30px 30px 50px 30px;
  }
}
.homepage--carrer_block h3 {
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}
.homepage--carrer_block .phone {
  border-top: 1px solid #ffffff;
  padding-top: 15px;
  margin-top: 15px;
  padding-left: 1.5em;
}
.homepage--carrer_block .phone:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f095";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.homepage--carrer_block .fax {
  padding-left: 1.5em;
}
.homepage--carrer_block .fax:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f02f";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.homepage--carrer_block .button {
  position: absolute;
  bottom: -20px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: calc(100% - (2 * 40px));
}
@media screen and (max-width: 480px) {
  .homepage--carrer_block .button {
    width: calc(100% - (2 * 30px));
  }
}
.homepage--carrer .modular-content {
  float: left;
  width: 74.358974359%;
}
@media screen and (max-width: 1036px) {
  .homepage--carrer .modular-content {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 480px) {
  .homepage--carrer .modular-content {
    margin-bottom: 20px;
  }
}

.page-3 article {
  float: left;
  width: 48.7179487179%;
  margin-right: 2.5641025641%;
  background: #e4ebf1;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .page-3 article {
    width: 100%;
    margin-right: 0;
  }
}
.page-3 article p {
  padding: 0 40px 30px 40px;
  margin-top: 0;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .page-3 article p {
    padding: 0 30px 30px 30px;
  }
}
.page-3 article p:first-of-type {
  padding-top: 30px;
}
.page-3 article p:last-of-type {
  padding: 0;
}
.page-3 article p a {
  text-decoration: none !important;
  color: #003465 !important;
}
.page-3 article p a:hover {
  background: none !important;
  text-decoration: underline !important;
}
.page-3 form {
  float: left;
  width: 48.7179487179%;
  clear: none;
  margin-top: 0 !important;
}
@media screen and (max-width: 768px) {
  .page-3 form {
    width: 100%;
    margin-top: 40px !important;
  }
}

.contact-person {
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 99999;
}
.contact-person__toggle {
  display: block;
  position: relative;
  z-index: 10;
  cursor: pointer;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #01a4a9;
  border-radius: 100%;
  transition: background 0.2s linear;
  color: #ffffff;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.15);
}
.contact-person__toggle:hover {
  background: #018b90;
}
.contact-person__details {
  display: none;
  position: absolute;
  padding: 20px;
  width: 350px;
  top: 20px;
  left: 20px;
  transform: translateX(-100%) translateY(-100%);
  background: #e4ebf1;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.15);
}
.contact-person__details .team-member__image {
  float: left;
  width: 100px;
  margin-right: 20px;
}
.contact-person__details .team-member__info {
  float: left;
  width: 190px;
}
.contact-person__details .team-member__info li.team-member__separator {
  display: block;
  width: 80px;
  height: 2px;
  margin: 10px 0 13.3333333333px 0;
  background: #01a4a9;
  line-height: 1;
}
.contact-person__details .team-member__info li i {
  margin-right: 0.5em;
  color: #01a4a9;
}
.contact-person__details .team-member__info li a {
  text-decoration: none;
  color: #003465;
  font-weight: 300;
}
.contact-person__details .team-member__info li a:hover {
  color: #01a4a9;
}

.cookie-confirm {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  position: fixed;
  z-index: 999999999999;
  right: calc(40px * 0.5);
  bottom: calc(40px * 0.5);
  width: 100%;
  max-width: calc(100% - 40px * 0.5);
  max-height: 90%;
  background: #ffffff;
  box-shadow: 5px 5px 20px -10px #000000;
  overflow: auto;
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 1037px) {
  .cookie-confirm {
    max-width: 50%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .cookie-confirm {
    max-width: 75%;
  }
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    max-height: 75% !important;
    right: calc(40px / 4);
    bottom: calc(40px / 4);
  }
}
.cookie-confirm__top > span {
  display: block;
  margin-bottom: calc(40px * 0.5);
  font-family: "Saira", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.35em;
}
.cookie-confirm__top > b {
  display: block;
  margin-top: calc(40px * 0.5);
}
.cookie-confirm__top > p {
  margin: 0;
}
.cookie-confirm__bottom {
  display: grid;
  justify-items: start;
}
@media screen and (min-width: 481px) {
  .cookie-confirm__bottom {
    grid-auto-columns: min-content auto;
    grid-template-areas: "checkboxes checkboxes" "submit cancel" "select-current select-current";
  }
}
@media screen and (max-width: 480px) {
  .cookie-confirm__bottom {
    grid-template-areas: "checkboxes" "submit" "cancel" "select-current";
  }
}
.cookie-confirm__bottom > br {
  display: none;
}
.cookie-confirm__checkboxes {
  position: relative;
  margin-bottom: 40px;
  text-align: left;
  grid-area: checkboxes;
}
.cookie-confirm__checkbox {
  position: relative;
  display: block;
  margin-top: calc(40px / 2);
}
.cookie-confirm__checkbox:first-of-type {
  margin-top: 0 !important;
}
.cookie-confirm__checkbox input {
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.cookie-confirm__checkbox span {
  display: block;
  margin-top: 0.5em;
  margin-left: calc(1.5rem + 3px);
  color: #0154a2;
  font-size: 0.75rem;
  line-height: 1.35em;
}

#cookie-confirm__submit,
#cookie-confirm__cancel {
  width: max-content;
  text-align: left;
}

#cookie-confirm__submit {
  grid-area: submit;
}
@media screen and (min-width: 481px) {
  #cookie-confirm__submit {
    margin-right: calc(40px * 0.25);
  }
}

#cookie-confirm__cancel {
  grid-area: cancel;
}
@media screen and (max-width: 480px) {
  #cookie-confirm__cancel {
    margin-top: calc(40px * 0.25);
  }
}

#cookie-confirm__select-current {
  margin-top: 20px;
  display: inline-block;
  cursor: pointer;
  grid-area: select-current;
}
@media screen and (max-width: 480px) {
  #cookie-confirm__select-current {
    margin-top: 10px;
  }
}

#cookie-revoke {
  cursor: pointer;
  position: fixed;
  right: 60px;
  bottom: 0;
  z-index: 9999999999;
  padding: 10px;
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px -10px #000000;
}
#cookie-revoke:hover {
  color: #01a4a9;
}

.footer {
  background: #001c37;
  color: #ffffff;
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .footer {
    margin-top: 40px;
  }
}
.footer--inner-wrapper {
  max-width: 1170px;
  width: calc(100% - (40px / 2));
  margin: 0 auto;
}
.footer--inner-wrapper a {
  color: #ffffff !important;
}
.footer--inner-wrapper_bottom {
  padding: 20px 0;
  border-top: 1px solid #003465;
}
@media screen and (min-width: 769px) {
  .footer--inner-wrapper_bottom span {
    float: left;
  }
  .footer--inner-wrapper_bottom ul {
    float: right;
  }
}
@media screen and (max-width: 768px) {
  .footer--inner-wrapper_bottom {
    text-align: center;
  }
  .footer--inner-wrapper_bottom span {
    margin-bottom: 10px;
    display: inline-block;
  }
}
.footer--inner-wrapper_top {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
  .footer--inner-wrapper_top {
    padding-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .footer--inner-wrapper_top {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 768px) {
  .footer--inner-wrapper_top .footer--box {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .footer--inner-wrapper_top .footer--box:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .footer--inner-wrapper_top .footer--box:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .footer--inner-wrapper_top .footer--box:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .footer--inner-wrapper_top .footer--box {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .footer--inner-wrapper_top .footer--box:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .footer--inner-wrapper_top .footer--box:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .footer--inner-wrapper_top .footer--box:nth-of-type(3n+4) {
    clear: both;
  }
}
.footer--inner-wrapper_top .footer--box span {
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  line-height: 0;
}
.footer--inner-wrapper_top .footer--box span:after {
  background: #003465;
}
@media screen and (max-width: 768px) {
  .footer--inner-wrapper_top .footer--box {
    margin-bottom: 20px;
  }
  .footer--inner-wrapper_top .footer--box:last-of-type {
    margin-bottom: 0;
  }
}
.footer--inner-wrapper_top .footer--box li {
  margin-bottom: 5px;
}
.footer--inner-wrapper_top .footer--box li:last-of-type {
  margin-bottom: 0;
}
.footer--inner-wrapper_top .footer--box li a:hover {
  text-decoration: underline;
}
.footer--inner-wrapper_top .footer--box:first-of-type .phone {
  padding-left: 1.5em;
}
.footer--inner-wrapper_top .footer--box:first-of-type .phone:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f095";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.footer--inner-wrapper_top .footer--box:first-of-type .fax {
  padding-left: 1.5em;
}
.footer--inner-wrapper_top .footer--box:first-of-type .fax:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f02f";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.footer--inner-wrapper_top .footer--box:first-of-type .email {
  padding-left: 1.5em;
}
.footer--inner-wrapper_top .footer--box:first-of-type .email:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f1d8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.footer--inner-wrapper_top .footer--box:last-of-type .navigation--main ul {
  display: block !important;
}
.footer--inner-wrapper_top .footer--box:last-of-type .navigation--main ul li {
  width: 50%;
  float: left;
  margin-bottom: 8px;
}
.footer--inner-wrapper_top .footer--box:last-of-type .navigation--main ul li a {
  border-bottom: 0 !important;
  padding-bottom: 0;
}
.footer--inner-wrapper_top .footer--box:last-of-type .navigation--main .mobile-navigation-button {
  display: none !important;
}

.main__text + form {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .main__text + form {
    margin-top: 20px;
  }
}

button,
input,
textarea,
select {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  color: inherit;
  line-height: 1.35em;
  outline: 0;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
}

label {
  cursor: pointer;
}

input,
textarea,
select {
  border: 1px solid #e4ebf1;
  background: transparent;
}
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
textarea:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
select:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]) {
  display: block;
  padding: 10px 13.3333333333px;
  width: 100%;
}
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled],
textarea:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled],
select:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}
input:focus,
textarea:focus,
select:focus {
  outline: 0;
  border: 1px solid #01a4a9;
}

input[type=submit],
input[type=reset] {
  width: auto;
}

form .field {
  margin-bottom: 15px;
}
form .field:last-child {
  margin-bottom: 0;
}
form .field.optionset > .middleColumn > div {
  margin-top: 10px;
}
form .field.optionset > .middleColumn > div:first-of-type {
  margin-top: 0;
}
form .field .message {
  display: block;
  margin-top: 20px;
}
form .field .description {
  display: block;
  margin-top: 10px;
  font-size: 0.75rem;
}
form .field .right-title, form .field > label.right {
  display: block;
  font-size: 0.75rem;
  margin-top: 10px;
  color: #0154a2;
}
form .field > label.left, form .field > legend.left {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}
form .field.requiredField > label.left:after {
  content: " *";
  color: #dd3c3c;
}
form .Actions {
  margin-top: 20px;
}

input[type=checkbox] {
  display: none;
  cursor: pointer;
}
input[type=checkbox] + label:before {
  margin-right: 0.5em;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f0c8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #003465;
}
input[type=checkbox]:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f14a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
input[type=checkbox][disabled] {
  cursor: not-allowed;
}
input[type=checkbox][disabled] + label:before {
  cursor: not-allowed;
  color: #0154a2;
}
input[type=radio] {
  display: none;
  cursor: pointer;
}
input[type=radio] + label:before {
  margin-right: 0.5em;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f111";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #003465;
}
input[type=radio]:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f192";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
input[type=radio][disabled] {
  cursor: not-allowed;
}
input[type=radio][disabled] + label:before {
  cursor: not-allowed;
  color: #0154a2;
}

@media screen and (min-width: 769px) {
  #MemberLoginForm_LoginForm,
#MemberLoginForm_LostPasswordForm {
    width: 50%;
  }
}

#MemberLoginForm_LoginForm #ForgotPassword {
  margin-top: 10px;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(228, 235, 241, 0.3);
  z-index: 1000;
  transition: 0.4s linear;
}
.header--inner-wrapper {
  background: #ffffff;
  max-width: 1170px;
  margin: 0 auto;
  padding: 30px 20px;
  position: relative;
}
@media screen and (max-width: 1170px) {
  .header--inner-wrapper {
    padding: 20px 10px;
  }
}
@media screen and (max-width: 480px) {
  .header--inner-wrapper {
    padding: 15px 10px;
  }
}
.header--inner-wrapper span {
  width: calc(100% - 40px);
  height: 5px;
  background: rgba(228, 235, 241, 0.65);
  display: block;
  position: absolute;
  bottom: -5px;
}
@media screen and (max-width: 1170px) {
  .header--inner-wrapper span {
    width: 100%;
    left: 0;
    right: 0;
  }
}
.header--inner-wrapper_left {
  float: left;
  border-right: 1px solid #e4ebf1;
  padding-right: 40px;
  margin-right: 40px;
}
@media screen and (max-width: 1036px) {
  .header--inner-wrapper_left {
    padding-right: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 900px) {
  .header--inner-wrapper_left {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}
.header--inner-wrapper_left .header__logo {
  display: inline-block;
  text-decoration: none;
}
.header--inner-wrapper_left .header__logo img {
  height: 42px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .header--inner-wrapper_left .header__logo img {
    max-width: 254px;
  }
}
@media screen and (max-width: 360px) {
  .header--inner-wrapper_left .header__logo img {
    max-width: 230px;
  }
}
.header--inner-wrapper_right {
  float: right;
  width: calc(100% - (301px + (40px * 2)));
  margin-top: 13px;
}
@media screen and (max-width: 1036px) {
  .header--inner-wrapper_right {
    width: calc(100% - (301px + 40px));
  }
}
@media screen and (max-width: 900px) {
  .header--inner-wrapper_right {
    margin-top: 5px;
  }
}
@media screen and (max-width: 480px) {
  .header--inner-wrapper_right {
    margin-top: 0;
    width: auto;
  }
}

.header-opacity {
  background-color: #edf2f6;
  opacity: 1;
  transition: 0.4s linear;
}

.page--no-banner .header {
  background-color: #edf2f6;
  opacity: 1;
}

.loader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: rgba(255, 255, 255, 0.8);
}
.loader .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -25px;
  display: block !important;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.loader .spinner > div {
  display: inline-block;
  height: 100%;
  width: 4px;
  background: #01a4a9;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.loader .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.mobile-navigation-scrollbarhider {
  display: none;
}
.mobile-navigation-scrollbarhider--force-deactived {
  display: none !important;
}

@media (max-resolution: 1dppx) {
  .mobile-navigation--force-active + .mobile-navigation-scrollbarhider {
    content: "";
    display: block;
    position: fixed;
    top: -10px;
    bottom: -10px;
    right: 268px;
    width: 17px;
    background: #ffffff;
    z-index: 9999;
  }
}
.mobile-navigation {
  position: fixed;
  overflow-y: scroll;
  z-index: 9998;
  top: 0;
  bottom: 0;
  width: 270px;
  transition: right 0.2s ease;
  right: -270px;
  background: #e4ebf1;
}
@media screen and (max-width: 900px) {
  .mobile-navigation--active, .mobile-navigation--force-active {
    right: 0;
    margin-right: 0;
  }
  .mobile-navigation:not(.mobile-navigation--active) {
    right: -270px !important;
  }
}
@media (max-resolution: 1dppx) {
  .mobile-navigation {
    width: 287px;
    margin-right: -17px;
  }
  .mobile-navigation--active, .mobile-navigation--force-active {
    margin-right: -17px;
  }
}

.mobile-navigation-overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;
  opacity: 0;
  visibility: hidden;
  background: #001c37;
  transition: all 0.2s ease;
}
@media screen and (max-width: 900px) {
  .mobile-navigation-overlay--active {
    opacity: 0.8;
    visibility: visible;
  }
}
.mobile-navigation-overlay--force-active {
  opacity: 0.8;
  visibility: visible;
}

.navigation {
  position: relative;
  z-index: 500;
}
.navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navigation ul li {
  position: relative;
  line-height: 1;
  font-size: 1rem;
}
.navigation ul li[class*="--current"] > a, .navigation ul li[class*="--section"] > a, .navigation ul li:hover > a {
  color: #01a4a9;
}
.navigation ul li a {
  display: block;
  color: #003465;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
}
.navigation .navigation__sub {
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.navigation--vertical .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--vertical ul li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top {
  font-size: 0;
}
.navigation--horizontal .navigation__top {
  display: flex;
  justify-content: space-between;
}
.navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
  top: 1rem;
  background: #e4ebf1;
  padding: 15px;
}
.navigation--horizontal .navigation__top-link:hover > .navigation__sub li {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.navigation--horizontal .navigation__top-link:hover > .navigation__sub li:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.navigation--horizontal .navigation__top-link:hover > .navigation__sub li a:hover {
  border-bottom: 0;
  padding-bottom: 0;
}
.navigation--horizontal .navigation__top-link > .navigation__sub {
  right: 0;
}
.navigation--horizontal .navigation__top-link .navigation__sub {
  min-width: 100%;
}
.navigation--horizontal .navigation__top-link .navigation__sub .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--horizontal .navigation__top-link .navigation__sub li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub {
  right: 0;
  left: auto;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub .navigation__sub {
  transform: translateX(-100%);
  right: 0;
  left: auto;
}
.navigation .mobile-navigation-button {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #003465;
}
.navigation .mobile-navigation-button:hover {
  color: #01a4a9;
}
.navigation--main .navigation__top-link {
  font-size: 1rem;
}
.navigation--main .navigation__top-link a:hover {
  color: #01a4a9;
  border-bottom: 1px solid #01a4a9;
  padding-bottom: 13px;
}
.navigation--main.navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  top: 1.5rem;
}
@media screen and (max-width: 900px) {
  .navigation--main .mobile-navigation-button {
    display: block;
    text-align: right;
  }
  .navigation--main .mobile-navigation-button i {
    padding: 8px 10px;
    border: 1px solid #e4ebf1;
  }
  .navigation--main .navigation__top {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .navigation--main .mobile-navigation-button i {
    padding: 7px 9px;
  }
}
@media screen and (max-width: 360px) {
  .navigation--main .mobile-navigation-button i {
    padding: 5px 7px;
  }
}
.navigation--footer ul li {
  margin-right: 20px;
}
.navigation--footer ul li:last-of-type {
  margin-right: 0;
}
.navigation--mobile ul li:hover > .navigation__sub {
  display: none;
  transform: translateX(0);
}
.navigation--mobile .navigation__sub {
  clear: both;
  position: static !important;
  display: none;
  border-top: 1px solid #ffffff;
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
}
.navigation--mobile .navigation__sub--active {
  display: block !important;
}
.navigation--mobile .navigation__sub .navigation__sub-link {
  border-bottom: 1px solid #ffffff;
}
.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 20px);
}
.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > .navigation__sub-toggle {
  padding: 10px;
  width: calc(1em + 20px);
}
.navigation--mobile .navigation__sub .navigation__sub-link:last-of-type {
  border-bottom: 0;
}
.navigation--mobile .navigation__sub-toggle {
  display: block;
  float: left;
  cursor: pointer;
  color: #0154a2;
  line-height: 1;
}
.navigation--mobile .navigation__sub-toggle:hover, .navigation--mobile .navigation__sub-toggle--active {
  color: #003465;
}
.navigation--mobile .navigation__sub-toggle--active i:before {
  content: "\f056";
}
.navigation--mobile .navigation__top-link {
  border-bottom: 1px solid #ffffff;
}
.navigation--mobile .navigation__top-link > a {
  padding: 20px;
  font-weight: 700;
}
.navigation--mobile .navigation__top-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 20px);
}
.navigation--mobile .navigation__top-link > .navigation__sub-toggle {
  padding: 20px 10px;
  width: calc(1em + 20px);
}
.navigation--mobile .navigation__top-link > .navigation__sub a {
  padding: 10px 20px;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > a {
  background: #f2f2f2;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > .navigation__sub-toggle {
  background: #f2f2f2;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > .navigation__sub > li > a {
  background: #e6e6e6;
}

@media screen and (max-width: 1170px) {
  .page-slider {
    margin-top: 87px;
  }
}
@media screen and (max-width: 480px) {
  .page-slider {
    margin-top: 70px;
  }
}
@media screen and (max-width: 360px) {
  .page-slider {
    margin-top: 66px;
  }
}
.page-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  display: block;
  color: #ffffff;
  font-size: 1.5rem;
}
.page-slider .slick-arrow:hover {
  color: #01a4a9;
}
.page-slider .slick-arrow.fa-chevron-left {
  left: 20px;
}
.page-slider .slick-arrow.fa-chevron-right {
  right: 20px;
}
.page-slider .slick-dots {
  position: absolute;
  z-index: 20;
  bottom: 20px;
  left: 0;
  right: 0;
  font-size: 0;
  text-align: center;
}
.page-slider .slick-dots li {
  display: inline-block;
  margin-right: 2px;
}
.page-slider .slick-dots li:last-of-type {
  margin-right: 0;
}
.page-slider .slick-dots li:last-of-type:first-of-type {
  display: none;
}
.page-slider .slick-dots li button {
  cursor: pointer;
  display: block;
  width: 13.3333333333px;
  height: 13.3333333333px;
  background: #e4ebf1;
  border-radius: 100%;
  font-size: 0;
  border: 0;
  box-shadow: none;
}
.page-slider .slick-dots li button:hover {
  background: #001c37;
}
.page-slider .slick-dots li.slick-active button {
  background: #01a4a9;
}
.page-slider .slick-dots li.slick-active button:hover {
  background: #017376;
}
.page-slider__slide {
  position: relative;
  background: #001c37;
}
.page-slider__slide img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0.7 !important;
}
.page-slider__caption {
  z-index: 10;
  position: absolute;
  transform: translateY(-50%);
  top: calc(50% + 30px);
  left: 0;
  right: 0;
}
@media screen and (max-width: 1170px) {
  .page-slider__caption {
    top: 50%;
  }
}
@media screen and (max-width: 1170px) {
  .page-slider__caption {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}
@media screen and (max-width: 480px) {
  .page-slider__caption {
    display: none;
  }
}
.page-slider__caption-content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1170px;
}
.page-slider__caption-content > h3 {
  font-size: 3.4375rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  color: #ffffff;
  text-shadow: 2px 2px 0px rgba(0, 52, 101, 0.3);
}
@media screen and (max-width: 900px) {
  .page-slider__caption-content > h3 {
    font-size: 2.8125rem;
  }
}
@media screen and (max-width: 650px) {
  .page-slider__caption-content > h3 {
    font-size: 2rem;
  }
}
.page-slider__caption-content > p {
  margin-top: 20px;
  font-size: 1.25rem;
  color: #ffffff;
  max-width: 780px;
  text-shadow: 2px 2px 0px rgba(0, 52, 101, 0.3);
}
@media screen and (max-width: 900px) {
  .page-slider__caption-content > p {
    font-size: 1rem;
    max-width: 100%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 580px) {
  .page-slider__caption-content > p {
    display: none;
  }
}
.page-slider__caption-content > p span {
  box-decoration-break: clone;
}
.page-slider__caption-content > a {
  margin-top: 32px;
  display: inline-block;
  color: #ffffff;
  padding-left: 1.5em;
  padding-left: 40px;
}
.page-slider__caption-content > a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.page-slider__caption-content > a:before {
  font-weight: 300;
}
@media screen and (max-width: 900px) {
  .page-slider__caption-content > a {
    margin-top: 20px;
  }
}
@media screen and (max-width: 650px) {
  .page-slider__caption-content > a {
    padding: 10px;
  }
  .page-slider__caption-content > a:before {
    display: none;
  }
}
.page-slider__caption-content.page-slider__caption-content--font-color-light > h3, .page-slider__caption-content.page-slider__caption-content--font-color-light > p, .page-slider__caption-content.page-slider__caption-content--font-color-light > a {
  color: #ffffff;
}

.page-title {
  margin-bottom: 30px;
  margin-top: 80px;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1170px) {
  .page-title {
    margin-top: 101px;
  }
}
@media screen and (max-width: 580px) {
  .page-title {
    margin-top: 40px;
  }
}
@media screen and (max-width: 480px) {
  .page-title {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.page-title h2 {
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .page-title h2 {
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
}
.page-title h1 {
  line-height: 1;
  font-size: 2.8125rem;
  font-weight: 600;
  font-family: "Saira", sans-serif;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .page-title h1 {
    font-size: 2rem;
  }
}
.page-title .breadcrumbs {
  margin-bottom: 10px;
  color: #0154a2;
}
.page-title .breadcrumbs a {
  color: #0154a2;
}
.page-title .breadcrumbs a:hover {
  color: #01a4a9;
}

.pagination {
  text-align: center;
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .pagination {
    padding-top: 20px;
  }
}
.pagination:before {
  display: block;
  content: "";
  border-top: 1px solid #01a4a9;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .pagination:before {
    margin-bottom: 20px;
  }
}
.pagination a,
.pagination span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #e4ebf1;
  border-radius: 4px;
}
.pagination a {
  color: #003465;
  text-decoration: none;
  transition: all 0.2s;
}
.pagination a:hover {
  background: #01a4a9;
  color: #FFFFFF;
}
.pagination span.pagination__current {
  background: #01a4a9;
  color: #FFFFFF;
}

#grasenhiller_referenceholder.page--no-banner .page-title {
  margin-top: 180px;
}
#grasenhiller_referenceholder.page--no-banner .reference-holder {
  margin-top: 60px;
}

.reference-list {
  position: relative;
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .reference-list {
    margin-top: 30px;
  }
}

.reference-detail .content {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .reference-detail .content {
    margin-bottom: 30px;
  }
}
.reference-detail__top {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .reference-detail__top {
    margin-bottom: 30px;
  }
}
.reference-detail__top img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  display: block;
}
.reference-detail__top .left {
  float: left;
  width: 48.7179487179%;
  margin-right: 2.5641025641%;
}
@media screen and (max-width: 580px) {
  .reference-detail__top .left {
    width: 100%;
    margin-right: 0;
  }
}
.reference-detail__top .left img {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .reference-detail__top .left img {
    margin-bottom: 2.5641025641%;
  }
}
.reference-detail__top .left .bottom img {
  width: calc((100% - 30px) / 2);
  float: left;
}
@media screen and (max-width: 768px) {
  .reference-detail__top .left .bottom img {
    width: 48.7179487179%;
  }
}
.reference-detail__top .left .bottom img:first-of-type {
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  .reference-detail__top .left .bottom img:first-of-type {
    margin-right: 2.5641025641%;
  }
}
.reference-detail__top .right {
  float: left;
  width: 48.7179487179%;
}
@media screen and (max-width: 580px) {
  .reference-detail__top .right {
    width: 100%;
  }
}
.reference-detail__top .right img {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .reference-detail__top .right img {
    margin-bottom: 2.5641025641%;
  }
}
.reference-detail__top .right img:last-of-type {
  margin-bottom: 0;
}
.reference-detail__bottom {
  width: 100%;
  border-top: 1px solid #e4ebf1;
  padding-top: 40px;
  margin-top: 40px;
}
.reference-detail__bottom .contact-form {
  width: 65.811965812%;
  float: left;
  margin-right: 2.5641025641%;
}
@media screen and (max-width: 768px) {
  .reference-detail__bottom .contact-form {
    width: 100%;
    margin-right: 0;
  }
}
.reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step div {
  width: calc((100% - 31px) / 2);
  margin-right: 30px;
  float: left;
}
@media screen and (max-width: 480px) {
  .reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step div {
    width: 100%;
    margin-right: 0;
  }
}
.reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step div:nth-child(2n) {
  margin-right: 0;
}
.reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step div div {
  width: 100%;
  margin: 0 !important;
}
.reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step .textarea,
.reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step .checkbox {
  width: 100% !important;
}
.reference-detail__bottom .contact-form #UserForm_Form_10 .userform-fields .form-step .checkbox label {
  float: left;
}
.reference-detail__bottom .contact-form-block {
  width: 31.6239316239%;
  float: left;
  border-radius: 4px;
  background: #e4ebf1;
  padding: 40px;
}
@media screen and (max-width: 768px) {
  .reference-detail__bottom .contact-form-block {
    width: 100%;
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  .reference-detail__bottom .contact-form-block {
    padding: 30px;
  }
}
.reference-detail__bottom .contact-form-block span {
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 400;
}
.reference-detail__bottom .contact-form-block ul .phone {
  padding-left: 1.5em;
}
.reference-detail__bottom .contact-form-block ul .phone:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f095";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.reference-detail__bottom .contact-form-block ul .fax {
  padding-left: 1.5em;
}
.reference-detail__bottom .contact-form-block ul .fax:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f02f";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}
.reference-detail__bottom .contact-form-block ul .email {
  padding-left: 1.5em;
}
.reference-detail__bottom .contact-form-block ul .email:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f1d8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1.25em;
  margin-left: -1.5em;
  float: left;
  text-align: left;
  position: relative;
  top: 3px;
}

.reference-preview {
  position: relative;
}
.reference-preview:nth-child(odd) .reference-preview__left {
  float: left;
  text-align: right;
}
.reference-preview:nth-child(odd) .reference-preview__left .icon {
  float: left;
}
@media screen and (max-width: 650px) {
  .reference-preview:nth-child(odd) .reference-preview__left .icon {
    position: absolute;
    top: 20px;
    left: 10px;
  }
}
@media screen and (max-width: 480px) {
  .reference-preview:nth-child(odd) .reference-preview__left .icon {
    top: 28px;
  }
}
.reference-preview:nth-child(odd) .reference-preview__big-image {
  position: relative;
}
.reference-preview:nth-child(odd) .reference-preview__big-image img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 4px 4px 0;
}
@media screen and (max-width: 1170px) {
  .reference-preview:nth-child(odd) .reference-preview__big-image {
    margin-left: 0 !important;
  }
  .reference-preview:nth-child(odd) .reference-preview__big-image img {
    border-radius: 4px;
  }
}
.reference-preview:nth-child(odd) .reference-preview__right {
  float: right;
  margin-left: 2.5641025641%;
}
.reference-preview:nth-child(even) .reference-preview__left {
  float: right;
  text-align: left;
}
.reference-preview:nth-child(even) .reference-preview__left .icon {
  float: right;
}
@media screen and (max-width: 650px) {
  .reference-preview:nth-child(even) .reference-preview__left .icon {
    position: absolute;
    top: 20px;
    left: 10px;
  }
}
@media screen and (max-width: 480px) {
  .reference-preview:nth-child(even) .reference-preview__left .icon {
    top: 28px;
  }
}
.reference-preview:nth-child(even) .reference-preview__big-image {
  position: relative;
}
.reference-preview:nth-child(even) .reference-preview__big-image img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 0 0 4px;
}
@media screen and (max-width: 1170px) {
  .reference-preview:nth-child(even) .reference-preview__big-image {
    margin-left: 0 !important;
  }
  .reference-preview:nth-child(even) .reference-preview__big-image img {
    border-radius: 4px;
  }
}
.reference-preview:nth-child(even) .reference-preview__right {
  float: left;
  margin-right: 2.5641025641%;
}
.reference-preview .outer-wrapper {
  margin-top: 0;
  border-bottom: 1px solid #e4ebf1;
  padding-bottom: 80px;
  margin-bottom: 90px;
}
@media screen and (max-width: 1170px) {
  .reference-preview .outer-wrapper {
    padding-bottom: 40px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 480px) {
  .reference-preview .outer-wrapper {
    padding-bottom: 30px;
    margin-bottom: 37.5px;
  }
}
.reference-preview:last-of-type .outer-wrapper {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.reference-preview__left {
  width: 57.264957265%;
}
@media screen and (max-width: 768px) {
  .reference-preview__left {
    width: 48.7179487179%;
  }
}
@media screen and (max-width: 650px) {
  .reference-preview__left {
    width: 100%;
  }
}
.reference-preview__left .icon {
  position: relative;
  z-index: 10;
  margin-top: -22.2222222222px;
  padding: 13.3333333333px;
  background: linear-gradient(45deg, #01a4a9 0%, #0154a2 100%);
  border-radius: 4px;
}
.reference-preview__left .icon i {
  color: #ffffff;
  font-weight: 300;
  font-size: 1.5rem;
}
@media screen and (max-width: 480px) {
  .reference-preview__left .icon {
    padding: 10px;
  }
}
.reference-preview__left img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.reference-preview__left > img {
  margin-top: 30px;
}
@media screen and (max-width: 650px) {
  .reference-preview__left > img {
    margin-top: 20px;
  }
}
.reference-preview__right {
  width: 40.1709401709%;
}
@media screen and (max-width: 768px) {
  .reference-preview__right {
    width: 48.7179487179%;
  }
}
@media screen and (max-width: 650px) {
  .reference-preview__right {
    width: 100%;
    margin-bottom: 20px;
  }
}
.reference-preview__right img {
  margin-top: 40px;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .reference-preview__right img {
    margin-top: 30px;
  }
}
.reference-preview__right .reference-preview__text h3 {
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}
@media screen and (max-width: 650px) {
  .reference-preview__right .reference-preview__text h3 {
    margin-left: 70px;
  }
}
@media screen and (max-width: 480px) {
  .reference-preview__right .reference-preview__text h3 {
    margin-left: 60px;
  }
}
.reference-preview__right .reference-preview__text p {
  border-bottom: 1px solid #e4ebf1;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.reference-preview__right .reference-preview__text a {
  text-decoration: none !important;
}
.reference-preview__right .reference-preview__text a:hover {
  text-decoration: underline !important;
  background: #ffffff !important;
  color: #01a4a9 !important;
}
.reference-preview__background {
  display: none;
  height: 35%;
  width: 50%;
  position: absolute;
  background: #e4ebf1;
  right: 15%;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.service-preview {
  margin-top: 40px;
  float: left;
}
@media screen and (max-width: 480px) {
  .service-preview {
    margin-top: 20px;
  }
}
.service-preview__image {
  float: left;
}
.service-preview__image a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  border-radius: 4px;
}
.service-preview__image a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #01a4a9;
  background: rgba(0, 0, 0, 0.85);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 2rem;
  width: 100%;
  transition: all 0.2s linear;
}
.service-preview__image a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(1, 164, 169, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.service-preview__image a:hover:before, .service-preview__image a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.service-preview__image a:hover:after {
  left: 4px;
}
.service-preview__image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.service-preview__text {
  float: left;
}
.service-preview__text h2 {
  font-size: 1.5rem;
}
.service-preview__text h2 a {
  text-decoration: none !important;
  color: #003465 !important;
  font-weight: 400;
}
.service-preview__text h2 a:hover {
  background: none !important;
  text-decoration: underline !important;
}
@media screen and (min-width: 769px) {
  .service-preview {
    margin-right: 2.5641025641%;
  }
}
@media screen and (max-width: 768px) {
  .service-preview {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .team-members .team-member,
.search-results__list--TeamMember .search-results__list-items .team-member {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .team-members .team-member:nth-of-type(-n + 1),
.search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .team-members .team-member:nth-of-type(1n+1),
.search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .team-members .team-member:nth-of-type(1n+2),
.search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .team-members .team-member,
.search-results__list--TeamMember .search-results__list-items .team-member {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .team-members .team-member:nth-of-type(-n + 2),
.search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .team-members .team-member:nth-of-type(2n+2),
.search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .team-members .team-member:nth-of-type(2n+3),
.search-results__list--TeamMember .search-results__list-items .team-member:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (max-width: 768px) {
  .team-members .team-member,
.search-results__list--TeamMember .search-results__list-items .team-member {
    margin-bottom: 20px;
  }
  .team-members .team-member:last-of-type,
.search-results__list--TeamMember .search-results__list-items .team-member:last-of-type {
    margin-bottom: 0;
  }
}
.team-members .team-member__image,
.search-results__list--TeamMember .search-results__list-items .team-member__image {
  position: relative;
  float: left;
  margin-right: 40px;
  width: calc(30% + 40px);
  background: #e4ebf1;
  text-align: center;
}
@media screen and (max-width: 1036px) {
  .team-members .team-member__image,
.search-results__list--TeamMember .search-results__list-items .team-member__image {
    margin-right: 20px;
    width: calc(30% - (40px / 2));
  }
}
.team-members .team-member__image img,
.search-results__list--TeamMember .search-results__list-items .team-member__image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.team-members .team-member__image--no-image,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 49.8220640569% !important;
  border-radius: 4px;
}
.team-members .team-member__image--no-image > *:first-child,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team-members .team-member__image--no-image > *:first-child:before,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image > *:first-child:before {
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: #001c37;
}
.team-members .team-member__info,
.search-results__list--TeamMember .search-results__list-items .team-member__info {
  float: left;
  width: calc(100% - (30% + (40px * 2)));
  margin-top: 20px;
}
@media screen and (max-width: 1036px) {
  .team-members .team-member__info,
.search-results__list--TeamMember .search-results__list-items .team-member__info {
    width: calc(100% - (30% + 40px));
    margin-top: 0;
  }
}
.team-members .team-member__info li,
.search-results__list--TeamMember .search-results__list-items .team-member__info li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-members .team-member__info .info-line-1,
.team-members .team-member__info .info-line-2,
.search-results__list--TeamMember .search-results__list-items .team-member__info .info-line-1,
.search-results__list--TeamMember .search-results__list-items .team-member__info .info-line-2 {
  white-space: normal;
}
.team-members .team-member__info i,
.search-results__list--TeamMember .search-results__list-items .team-member__info i {
  margin-right: 0.5em;
  color: #01a4a9;
}
.team-members .team-member__separator,
.search-results__list--TeamMember .search-results__list-items .team-member__separator {
  line-height: 1;
  height: 20px;
  border-top: 1px solid #e4ebf1;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .team-members .team-member__separator,
.search-results__list--TeamMember .search-results__list-items .team-member__separator {
    display: none;
  }
}
.team-members .team-member__title,
.search-results__list--TeamMember .search-results__list-items .team-member__title {
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
}
@media screen and (max-width: 480px) {
  .team-members .team-member__title,
.search-results__list--TeamMember .search-results__list-items .team-member__title {
    font-size: 1.25rem;
    margin-bottom: 6.6666666667px;
  }
  .team-members .team-member__title:after,
.search-results__list--TeamMember .search-results__list-items .team-member__title:after {
    display: none;
  }
}

.team--teaser {
  position: relative;
  margin: 120px 0;
}
@media screen and (max-width: 1820px) {
  .team--teaser {
    margin: 80px 0;
  }
}
@media screen and (max-width: 1170px) {
  .team--teaser {
    width: calc(100% - (40px / 2));
    margin: 40px auto;
  }
}
.team--teaser_left {
  background: #003465;
  color: #ffffff;
  float: left;
  border-radius: 0 4px 4px 0;
  width: 100%;
  padding: 80px 0;
}
@media screen and (max-width: 1170px) {
  .team--teaser_left {
    width: 57.264957265%;
    padding: 40px 0;
  }
}
@media screen and (max-width: 768px) {
  .team--teaser_left {
    width: 100%;
    border-radius: 4px 4px 0 0;
  }
}
.team--teaser_left .content-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  width: 100%;
}
.team--teaser_left .content-wrapper .content-wrapper-inner {
  width: 57.264957265%;
  float: left;
  padding-right: 60px;
}
@media screen and (max-width: 1170px) {
  .team--teaser_left .content-wrapper .content-wrapper-inner {
    width: 100%;
    padding: 0 30px;
  }
}
.team--teaser_left .content-wrapper .content-wrapper-inner h2 {
  font-family: "Saira", sans-serif;
  font-size: 2.8125rem;
  font-weight: 600;
  text-transform: uppercase;
}
.team--teaser_left .content-wrapper .content-wrapper-inner h2:after {
  background: #ffffff;
}
@media screen and (max-width: 768px) {
  .team--teaser_left .content-wrapper .content-wrapper-inner h2 {
    font-size: 2rem;
  }
}
.team--teaser_left .content-wrapper .content-wrapper-inner h3 {
  font-family: "Saira", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .team--teaser_left .content-wrapper .content-wrapper-inner h3 {
    font-size: 1.25rem;
  }
}
.team--teaser_left .content-wrapper .content-wrapper-inner p {
  margin-top: 30px;
  float: left;
  margin-right: 30px;
}
.team--teaser_left .content-wrapper .content-wrapper-inner p:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 1170px) {
  .team--teaser_left .content-wrapper .content-wrapper-inner p {
    margin-right: 15px;
    margin-top: 15px;
  }
}
.team--teaser_left .content-wrapper .content-wrapper-inner hr {
  background: #0154a2;
  margin: 20px 0;
}
.team--teaser_right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 30px 0 30px 30px;
  background: #ffffff;
}
@media screen and (max-width: 1530px) {
  .team--teaser_right {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 1170px) {
  .team--teaser_right {
    width: 40.1709401709%;
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .team--teaser_right {
    width: 100%;
    position: relative;
    padding: 0;
    float: left;
    transform: none;
  }
}
.team--teaser_right img {
  border-radius: 4px 0 0 4px;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1820px) {
  .team--teaser_right img {
    max-width: 700px;
  }
}
@media screen and (max-width: 1530px) {
  .team--teaser_right img {
    max-width: 500px;
  }
}
@media screen and (max-width: 768px) {
  .team--teaser_right img {
    max-width: 100%;
    border-radius: 0 0 4px 4px;
  }
}

.homepage--teaser-boxes {
  margin-top: 80px;
}
@media screen and (max-width: 580px) {
  .homepage--teaser-boxes {
    margin-top: 60px;
  }
}
.homepage--teaser-boxes .teaser-box {
  position: relative;
}
@media screen and (max-width: 768px) {
  .homepage--teaser-boxes .teaser-box {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .homepage--teaser-boxes .teaser-box:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .homepage--teaser-boxes .teaser-box:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .homepage--teaser-boxes .teaser-box:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .homepage--teaser-boxes .teaser-box {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .homepage--teaser-boxes .teaser-box:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .homepage--teaser-boxes .teaser-box:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .homepage--teaser-boxes .teaser-box:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 768px) {
  .homepage--teaser-boxes .teaser-box {
    margin-bottom: 40px;
  }
  .homepage--teaser-boxes .teaser-box:last-of-type {
    margin-bottom: 0;
  }
}
.homepage--teaser-boxes .teaser-box .icon {
  font-size: 1.5rem;
  padding: 13.3333333333px;
  border-radius: 4px;
  background: linear-gradient(45deg, #01a4a9 0%, #0154a2 100%);
  position: absolute;
  top: -15px;
  left: -15px;
}
@media screen and (max-width: 768px) {
  .homepage--teaser-boxes .teaser-box .icon {
    top: -25px;
    left: 40px;
  }
}
@media screen and (max-width: 480px) {
  .homepage--teaser-boxes .teaser-box .icon {
    left: 30px;
    padding: 10px;
    top: -22px;
  }
}
.homepage--teaser-boxes .teaser-box .icon i {
  color: #ffffff;
  font-weight: 300;
}
.homepage--teaser-boxes .teaser-box .content {
  background: #e4ebf1;
  padding: 40px;
  border-radius: 4px;
}
@media screen and (max-width: 480px) {
  .homepage--teaser-boxes .teaser-box .content {
    padding: 40px 30px 30px 30px;
  }
}
.homepage--teaser-boxes .teaser-box .content h3 {
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  font-weight: 400;
}
.homepage--teaser-boxes .teaser-box .content p {
  border-bottom: 1px solid #ffffff;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.sticky-toolbar {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 50%;
  transform: rotate(-90deg) translateY(-100%) translateX(50%);
  transform-origin: top right;
}
.sticky-toolbar_links a {
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  white-space: nowrap;
  display: block;
  float: left;
}
@media screen and (max-width: 480px) {
  .sticky-toolbar_links a {
    padding: 10px 15px;
  }
}
@media screen and (max-width: 480px) {
  .sticky-toolbar_links a:first-of-type {
    display: none;
  }
}
.sticky-toolbar_links a:first-of-type + a {
  margin-left: 6.6666666667px;
}
.sticky-toolbar_links a:last-of-type {
  background: linear-gradient(45deg, #F09900 0%, #F55200 100%);
}
.sticky-toolbar_links a:last-of-type:hover {
  background: linear-gradient(45deg, #BD7800 0%, #C24100 100%) !important;
}

.back-to-top {
  display: none;
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 20px;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(45deg, #01a4a9 0%, #0154a2 100%);
  color: #ffffff !important;
  border-radius: 4px;
}
.back-to-top:hover {
  background: #018b90;
}
.back-to-top i:before {
  position: relative;
  top: -1px;
}

html .userform-fields {
  margin-top: 0 !important;
}
html .userform-progress {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  html .userform-progress {
    margin-bottom: 20px;
  }
}
html .userform-progress > p {
  display: none;
}
html .userform-progress .progress {
  background: #e4ebf1;
  height: 26.6666666667px;
}
html .userform-progress .progress-bar {
  background: #001c37;
  height: 26.6666666667px;
}
html .userform-progress > nav {
  margin-top: -26.6666666667px;
}
html .userform-progress > nav ul {
  font-size: 0;
}
html .userform-progress > nav ul li {
  font-size: 1rem;
  height: 26.6666666667px;
}
html .userform-progress > nav ul li button {
  display: block;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 26.6666666667px;
  min-width: 40px;
  text-align: center;
  background: #ffffff;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  color: #003465;
}
html .userform-progress > nav ul li:first-of-type button {
  border-left: 0 !important;
}
html .userform-progress > nav ul li:last-of-type button {
  border-right: 0 !important;
}
html .userform-progress > nav ul li.viewed button {
  cursor: pointer;
}
html .userform .progress-title {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  html .userform .progress-title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
html .userform .FormHeading {
  margin-bottom: 20px;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Saira", sans-serif;
  font-weight: 600;
}
html .userform .step-navigation ul {
  font-size: 0;
}
html .userform .step-navigation ul li {
  font-size: 1rem;
}
html .userform .step-navigation ul li button.step-button-prev:not([style="display: none;"]) {
  margin-right: 10px;
}
html .userform .userformsgroup {
  margin-top: 20px;
  border: 1px solid #01a4a9;
  border-radius: 4px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
}
@media screen and (max-width: 768px) {
  html .userform .userformsgroup {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  html .userform .userformsgroup {
    padding-right: 20px;
    padding-left: 20px;
  }
}
html .userform .userformsgroup > legend {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -10px;
  border: 0;
  width: auto;
  font-weight: 700;
}
html .userform .step-button-wrapper.Actions {
  margin-top: 0;
  float: right;
}

#grasenhiller_appointmentpage .main__text + .calendar {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_appointmentpage .main__text + .calendar {
    margin-top: 20px;
  }
}
#grasenhiller_appointmentpage .calendar + form {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_appointmentpage .calendar + form {
    margin-top: 20px;
  }
}
#grasenhiller_appointmentpage form fieldset #FirstNameSurname .fieldgroup-field,
#grasenhiller_appointmentpage form fieldset #EmailPhone .fieldgroup-field {
  width: 48.7179487179%;
  float: left;
}
#grasenhiller_appointmentpage form fieldset #FirstNameSurname .fieldgroup-field:first-of-type,
#grasenhiller_appointmentpage form fieldset #EmailPhone .fieldgroup-field:first-of-type {
  margin-right: 2.5641025641%;
}
#grasenhiller_appointmentpage form fieldset .requiredField label:after {
  content: "*";
  color: red;
  margin-left: 5px;
}
#grasenhiller_appointmentpage form fieldset label {
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

#grasenhiller_bathshowroompage.page--no-banner .page-title {
  margin-top: 180px;
}
@media screen and (max-width: 1170px) {
  #grasenhiller_bathshowroompage.page--no-banner .page-title {
    margin-top: 140px;
  }
}
@media screen and (max-width: 580px) {
  #grasenhiller_bathshowroompage.page--no-banner .page-title {
    margin-top: 132px;
  }
}
@media screen and (max-width: 480px) {
  #grasenhiller_bathshowroompage.page--no-banner .page-title {
    margin-top: 105px;
  }
}
@media screen and (max-width: 360px) {
  #grasenhiller_bathshowroompage.page--no-banner .page-title {
    margin-top: 95px;
  }
}

#grasenhiller_bathshowroompage .page-title {
  margin-top: 100px;
}
@media screen and (max-width: 1170px) {
  #grasenhiller_bathshowroompage .page-title {
    margin-top: 101px;
  }
}
@media screen and (max-width: 580px) {
  #grasenhiller_bathshowroompage .page-title {
    margin-top: 40px;
  }
}
@media screen and (max-width: 480px) {
  #grasenhiller_bathshowroompage .page-title {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.bath-showroom__top {
  position: relative;
  z-index: 10;
}
.bath-showroom__top img {
  border-radius: 4px;
  width: 100%;
  height: auto;
}
.bath-showroom__top .left {
  width: 40.1709401709%;
  float: left;
  margin-right: 2.5641025641%;
}
@media screen and (max-width: 480px) {
  .bath-showroom__top .left {
    width: 100%;
    margin-right: 0;
  }
}
.bath-showroom__top .left img {
  margin-top: 30px;
}
.bath-showroom__top .right {
  width: 57.264957265%;
  float: left;
}
@media screen and (max-width: 480px) {
  .bath-showroom__top .right {
    width: 100%;
    margin-top: 30px;
  }
}
.bath-showroom__top .right .bath-showroom__quote {
  margin-top: 60px;
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
  font-style: italic;
}
.bath-showroom__top .right img {
  box-shadow: 20px 20px 0px 0px #e4ebf1;
}
.bath-showroom__middle {
  margin-top: 80px;
  position: relative;
  z-index: 9;
}
@media screen and (max-width: 768px) {
  .bath-showroom__middle {
    margin-top: 40px;
  }
}
.bath-showroom__middle .bath-showroom__middle-background {
  width: 40%;
  height: 300px;
  background: #e4ebf1;
  position: absolute;
  top: -300px;
}
@media screen and (max-width: 1036px) {
  .bath-showroom__middle .bath-showroom__middle-background {
    width: 60%;
  }
}
@media screen and (max-width: 480px) {
  .bath-showroom__middle .bath-showroom__middle-background {
    display: none;
  }
}
.bath-showroom__middle .bath-showroom__middle-slider {
  position: relative;
}
.bath-showroom__middle .bath-showroom__middle-slider .slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  font-size: 0;
  left: 40px;
  padding: 18px 19px;
  cursor: pointer;
}
.bath-showroom__middle .bath-showroom__middle-slider .slick-prev:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f177";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  .bath-showroom__middle .bath-showroom__middle-slider .slick-prev {
    padding: 10px 11px;
    left: 0;
    border-radius: 0 4px 4px 0;
  }
}
.bath-showroom__middle .bath-showroom__middle-slider .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  font-size: 0;
  right: 40px;
  padding: 18px 19px;
  cursor: pointer;
}
.bath-showroom__middle .bath-showroom__middle-slider .slick-next:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  .bath-showroom__middle .bath-showroom__middle-slider .slick-next {
    padding: 10px 11px;
    right: 0;
    border-radius: 4px 0 0 4px;
  }
}
.bath-showroom__bottom {
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .bath-showroom__bottom {
    margin-top: 40px;
  }
}
.bath-showroom__bottom .modular-content .textimage--image-right img {
  height: auto;
  margin-right: 2.5641025641%;
}
.bath-showroom__bottom .modular-content .textimage--image-left img {
  float: left;
  width: 100%;
  height: auto;
  margin-right: 2.5641025641%;
}
@media screen and (min-width: 481px) {
  .bath-showroom__bottom .modular-content .textimage--image-left .textimage__image,
.bath-showroom__bottom .modular-content .textimage--image-right .textimage__image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 40.1709401709%;
  }
}
@media screen and (min-width: 481px) {
  .bath-showroom__bottom .modular-content .textimage--image-left .formatted-text,
.bath-showroom__bottom .modular-content .textimage--image-right .formatted-text {
    width: 57.264957265%;
    float: left;
  }
}
.bath-showroom__bottom .modular-content .textimage--image-left .formatted-text a,
.bath-showroom__bottom .modular-content .textimage--image-right .formatted-text a {
  float: left;
}
.bath-showroom__bottom .modular-content .textimage--image-left .formatted-text a:after, .bath-showroom__bottom .modular-content .textimage--image-left .formatted-text a:before,
.bath-showroom__bottom .modular-content .textimage--image-right .formatted-text a:after,
.bath-showroom__bottom .modular-content .textimage--image-right .formatted-text a:before {
  display: none;
}

#grasenhiller_homepage .homepage--carrer .module__title h3 {
  font-size: 2.8125rem;
}
@media screen and (max-width: 768px) {
  #grasenhiller_homepage .homepage--carrer .module__title h3 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1036px) {
  .service-holder__children--ipr-1 .service-preview {
    width: 48.7179487179%;
  }
  .service-holder__children--ipr-1 .service-preview:nth-of-type(2n+2) {
    margin-right: 0;
  }
  .service-holder__children--ipr-1 .service-preview:nth-of-type(2n+3) {
    clear: both;
  }
  .service-holder__children--ipr-1 .service-preview:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .service-holder__children--ipr-1 .service-preview__image {
    width: 29.8245614035%;
    margin-right: 5.2631578947%;
  }
  .service-holder__children--ipr-1 .service-preview__text {
    width: 64.9122807018%;
  }
}
@media screen and (max-width: 768px) {
  .service-holder__children--ipr-1 .service-preview {
    width: 100%;
  }
  .service-holder__children--ipr-1 .service-preview:first-of-type {
    margin-top: 0;
  }
  .service-holder__children--ipr-1 .service-preview__image {
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
  }
  .service-holder__children--ipr-1 .service-preview__text {
    width: 65.811965812%;
  }
}
@media screen and (max-width: 360px) {
  .service-holder__children--ipr-1 .service-preview__image {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .service-holder__children--ipr-1 .service-preview__text {
    width: 100%;
  }
}
@media screen and (min-width: 1037px) {
  .service-holder__children--ipr-1 .service-preview__image {
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
  }
  .service-holder__children--ipr-1 .service-preview__text {
    width: 74.358974359%;
  }
}
@media screen and (min-width: 769px) {
  .service-holder__children--ipr-2 .service-preview {
    width: 48.7179487179%;
  }
  .service-holder__children--ipr-2 .service-preview:nth-of-type(2n+2) {
    margin-right: 0;
  }
  .service-holder__children--ipr-2 .service-preview:nth-of-type(2n+3) {
    clear: both;
  }
  .service-holder__children--ipr-2 .service-preview:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .service-holder__children--ipr-2 .service-preview__image {
    width: 29.8245614035%;
    margin-right: 5.2631578947%;
  }
  .service-holder__children--ipr-2 .service-preview__text {
    width: 64.9122807018%;
  }
}
@media screen and (max-width: 768px) {
  .service-holder__children--ipr-2 .service-preview {
    width: 100%;
  }
  .service-holder__children--ipr-2 .service-preview:first-of-type {
    margin-top: 0;
  }
  .service-holder__children--ipr-2 .service-preview__image {
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
  }
  .service-holder__children--ipr-2 .service-preview__text {
    width: 65.811965812%;
  }
}
@media screen and (max-width: 360px) {
  .service-holder__children--ipr-2 .service-preview__image {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .service-holder__children--ipr-2 .service-preview__text {
    width: 100%;
  }
}
@media screen and (min-width: 1037px) {
  .service-holder__children--ipr-3 .service-preview {
    width: 31.6239316239%;
  }
  .service-holder__children--ipr-3 .service-preview:nth-of-type(3n+3) {
    margin-right: 0;
  }
  .service-holder__children--ipr-3 .service-preview:nth-of-type(3n+4) {
    clear: both;
  }
  .service-holder__children--ipr-3 .service-preview:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .service-holder__children--ipr-3 .service-preview__image {
    float: none;
    margin-bottom: 20px;
  }
  .service-holder__children--ipr-3 .service-preview__text {
    float: none;
  }
}
@media screen and (max-width: 768px) {
  .service-holder__children--ipr-3 .service-preview {
    width: 100%;
  }
  .service-holder__children--ipr-3 .service-preview:first-of-type {
    margin-top: 0;
  }
  .service-holder__children--ipr-3 .service-preview__image {
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
  }
  .service-holder__children--ipr-3 .service-preview__text {
    width: 65.811965812%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .service-holder__children--ipr-3 .service-preview {
    width: 48.7179487179%;
  }
  .service-holder__children--ipr-3 .service-preview:nth-of-type(2n+2) {
    margin-right: 0;
  }
  .service-holder__children--ipr-3 .service-preview:nth-of-type(2n+3) {
    clear: both;
  }
  .service-holder__children--ipr-3 .service-preview:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .service-holder__children--ipr-3 .service-preview__image {
    width: 29.8245614035%;
    margin-right: 5.2631578947%;
  }
  .service-holder__children--ipr-3 .service-preview__text {
    width: 64.9122807018%;
  }
}
@media screen and (max-width: 768px) {
  .service-holder__children--ipr-3 .service-preview {
    width: 100%;
  }
  .service-holder__children--ipr-3 .service-preview:first-of-type {
    margin-top: 0;
  }
  .service-holder__children--ipr-3 .service-preview__image {
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
  }
  .service-holder__children--ipr-3 .service-preview__text {
    width: 65.811965812%;
  }
}
@media screen and (max-width: 360px) {
  .service-holder__children--ipr-3 .service-preview__image {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .service-holder__children--ipr-3 .service-preview__text {
    width: 100%;
  }
}

.service-page__preview-text {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .service-page__preview-text {
    margin-bottom: 20px;
  }
}
.service-page__preview-text p {
  font-size: 1.5rem;
}
.service-page__text p {
  overflow: visible;
}
.service-page__text p:first-of-type {
  margin-top: 0;
}
.service-page__image {
  display: block;
  width: 31.6239316239%;
  float: right;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.service-page__image:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #01a4a9;
  background: rgba(0, 0, 0, 0.85);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 2rem;
  width: 100%;
  transition: all 0.2s linear;
}
.service-page__image:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(1, 164, 169, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.service-page__image:hover:before, .service-page__image:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.service-page__image:hover:after {
  left: 4px;
}
.service-page__image img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 480px) {
  .service-page__image {
    width: 100%;
    margin-left: 0;
    float: none;
  }
}
.service-page__media {
  margin-top: 40px;
}
@media screen and (max-width: 480px) {
  .service-page__media .page-media__images li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .service-page__media .page-media__images li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .service-page__media .page-media__images li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .service-page__media .page-media__images li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .service-page__media .page-media__images li {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .service-page__media .page-media__images li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .service-page__media .page-media__images li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .service-page__media .page-media__images li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .service-page__media .page-media__images li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .service-page__media .page-media__images li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .service-page__media .page-media__images li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .service-page__media .page-media__images li:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .service-page__media .page-media__images li {
    float: left;
    width: 14.5299145299%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .service-page__media .page-media__images li:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .service-page__media .page-media__images li:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .service-page__media .page-media__images li:nth-of-type(6n+7) {
    clear: both;
  }
}
.service-page__media .page-media__images li a {
  border-radius: 4px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.service-page__media .page-media__images li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #01a4a9;
  background: rgba(0, 0, 0, 0.85);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 2rem;
  width: 100%;
  transition: all 0.2s linear;
}
.service-page__media .page-media__images li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(1, 164, 169, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.service-page__media .page-media__images li a:hover:before, .service-page__media .page-media__images li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.service-page__media .page-media__images li a:hover:after {
  left: 4px;
}
.service-page__media .page-media__images li a img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.service-page__media .page-media__files {
  clear: both;
  padding-top: 40px;
}
.service-page__media .page-media__files li {
  width: 100%;
  border-bottom: 1px dashed #e4ebf1;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 1.25rem;
}
@media screen and (max-width: 768px) {
  .service-page__media .page-media__files li {
    font-size: 1.125rem;
  }
}
.service-page__media .page-media__files li:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.service-page__media .page-media__files li a {
  float: left;
}
.service-page__media .page-media__files li a i {
  padding-right: 13.3333333333px;
  color: #01a4a9;
  font-size: 1.5rem;
}
.service-page__media .page-media__files li span {
  float: right;
}
.service-page__media:not(.service-page__media--both) iframe {
  width: 48.7179487179%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 769px) {
  .service-page__media--both iframe,
.service-page__media--both .page-media__images {
    float: left;
    width: 48.7179487179%;
  }
  .service-page__media--both iframe {
    margin-right: 2.5641025641%;
  }
}
.service-page__media--both .page-media__images li {
  margin-bottom: 2.5641025641%;
}
@media screen and (max-width: 480px) {
  .service-page__media--both .page-media__images li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .service-page__media--both .page-media__images li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .service-page__media--both .page-media__images li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .service-page__media--both .page-media__images li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  .service-page__media--both .page-media__images li {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .service-page__media--both .page-media__images li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .service-page__media--both .page-media__images li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .service-page__media--both .page-media__images li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 768px) {
  .service-page__media--both .page-media__images {
    margin-top: 40px;
  }
}
.service-page__contact {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .service-page__contact--both form,
.service-page__contact--both .service-page__contact-person {
    float: left;
    width: 48.7179487179%;
  }
  .service-page__contact--both .service-page__contact-person {
    margin-right: 2.5641025641%;
  }
  .service-page__contact--both form {
    clear: none !important;
  }
}
@media screen and (max-width: 768px) {
  .service-page__contact--both form {
    margin-top: 40px;
  }
}
.service-page__contact-person {
  width: 48.7179487179%;
}
.service-page__contact-person > strong {
  display: block;
  margin-bottom: 20px;
}
.service-page__contact-person .team-member {
  background: #e4ebf1;
  padding: 30px;
  border-radius: 4px;
}
@media screen and (max-width: 480px) {
  .service-page__contact-person .team-member {
    padding: 0;
    background: none;
  }
}
.service-page__contact-person .team-member__image {
  float: left;
  width: 29.8245614035%;
  margin-right: 5.2631578947%;
}
.service-page__contact-person .team-member__image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.service-page__contact-person .team-member__info {
  float: left;
  width: 64.9122807018%;
}
.service-page__contact-person .team-member__info .team-member__title {
  font-size: 1.5rem;
  font-family: "Saira", sans-serif;
}
@media screen and (max-width: 480px) {
  .service-page__contact-person .team-member__info .team-member__title {
    font-size: 1.25rem;
    margin-bottom: 6.6666666667px;
  }
  .service-page__contact-person .team-member__info .team-member__title:after {
    display: none;
  }
}
.service-page__contact-person .team-member__info i {
  color: #01a4a9;
}
.service-page__contact-person .team-member__info .team-member__separator {
  line-height: 1;
  height: 10px;
  margin-top: 10px;
}
@media screen and (max-width: 480px) {
  .service-page__contact-person .team-member__info .team-member__separator {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .service-page__contact-person {
    width: 100%;
  }
  .service-page__contact-person .team-member__image {
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
  }
  .service-page__contact-person .team-member__info {
    width: 65.811965812%;
  }
}
@media screen and (max-width: 360px) {
  .service-page__contact-person .team-member__image {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 360px) and (max-width: 768px) {
  .service-page__contact-person .team-member__image {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

#teampage .main__text {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  #teampage .main__text {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  #teampage .team-members .team-member {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  #teampage .team-members .team-member:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  #teampage .team-members .team-member:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  #teampage .team-members .team-member:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  #teampage .team-members .team-member {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  #teampage .team-members .team-member:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  #teampage .team-members .team-member:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  #teampage .team-members .team-member:nth-of-type(2n+3) {
    clear: both;
  }
}