.address-bar {
  @extend %outer-container;
  position: relative;
  z-index: 100;
  margin-top: -60px + -80px * 0.5;

  @include breakpoint(null, $max-width) {
    margin-top: -50px + -60px * 0.5;
    width: 100%;
  }

  @include breakpoint(null, 580px) {
    margin-top: -44px;
  }

  &--inner {
    .address-bar--slider-buttons {
      width: 100%;
      text-align: right;

      i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;

        font-weight: $fw-light;
        font-size: $fs-h4;
        cursor: pointer;

        @include breakpoint(null, $max-width) {
          width: 50px;
          height: 50px;
          line-height: 50px;
        }

        @include breakpoint(null, 580px) {
          width: 42px;
          height: 42px;
          line-height: 42px;
        }
      }

      .fa-long-arrow-left {
        background-color: rgba($box-color, 0.65);
        border-radius: $radius 0 0 0;
        transition: $duration;
        &:hover {
          background: $box-color;
        }

        @include breakpoint(null, $max-width) {
          border-radius: 0;
        }
      }

      .fa-long-arrow-right {
        background-color: $box-color;
        border-radius: 0 $radius 0 0;

        @include breakpoint(null, $max-width) {
          border-radius: 0;
        }
      }
    }

    .address-bar--inner-wrapper {
      background: $box-color;
      border-radius: $radius 0 $radius $radius;
      padding: 0 $gutter-width;
      height: 80px;
      line-height: 80px;
      width: 100%;

      @include breakpoint(null, $max-width) {
        border-radius: 0;
        line-height: 60px;
        height: 60px;
        padding: 0 $dist * 0.5;
      }

      @include breakpoint(null, 580px) {
        display: none;
      }

      span {
        border-right: 1px solid $page-bg;
        display: inline-block;
        margin-right: calc(#{$dist} - 2px);
        padding-right: calc(#{$dist} - 2px);
        font-size: $fs;
        margin-left: -0.25em;

        &:first-of-type {
          margin-left: 0;
        }

        @include breakpoint(null, $max-width) {
          margin-right: calc((#{$dist} / 2) - 2px);
          padding-right: calc((#{$dist} / 2) - 2px);
        }

        @include breakpoint(null, $bp-l) {
          border-right: 0;
          padding-right: 0;
        }

        @include breakpoint(null, 980px) {
          &:first-of-type {
            display: none;
          }
        }

        &:last-of-type {
          margin-right: 0;
          padding-right: 0;
          border-right: none;
        }

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .phone {
        @include icon-before($fa-var-phone);
      }

      .fax {
        @include icon-before($fa-var-print);
      }

      .email {
        @include icon-before($fa-var-paper-plane);
      }
    }
  }
}
