@use "sass:math";

.homepage--teaser-boxes {
	@extend %cf;
	margin-top: $dist*2;
	@extend %outer-container;

	@include breakpoint(null, 580px) {
		margin-top: $dist*1.5;
	}

	$grid-conf: (
	   (null, $bp-m, 1),
	   ($bp-m, null, 3)
	);

	.teaser-box {
		@include resp-gallery-layout($grid-conf);
		position: relative;

		@include breakpoint(null, $bp-m) {
			margin-bottom: $dist;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.icon {
			font-size: $fs-h4;
			padding: math.div($dist, 3);
			border-radius: $radius;
			background: $ac-gradiant;
			position: absolute;
			top: -$gutter-width*0.5;
			left: -$gutter-width*0.5;

			@include breakpoint(null, $bp-m) {
				top: -25px;
				left: $dist;
			}

			@include breakpoint(null, $bp-s) {
				left: $gutter-width;
				padding: $dist*0.25;
				top: -22px;
			}

			i {
				color: $page-bg;
				font-weight: $fw-light;
			}
		}

		.content {
			background: $box-color;
			padding: $dist;
			border-radius: $radius;
			@extend %cf;

			@include breakpoint(null, $bp-s) {
				padding: $dist $gutter-width $gutter-width $gutter-width;
			}

			h3 {
				font-size: $fs-h4;
				font-family: $ff-h;
				font-weight: $fw;
				@extend %border-bottom-small;
			}

			p {
				border-bottom: 1px solid $page-bg;
				width: 100%;
				padding-bottom: $gutter-width*0.5;
				margin-bottom: $gutter-width*0.5;
			}

			a {
				@extend %button--small;
			}
		}
	}
}