.page-title {
	margin-bottom: $dist*0.75;
	margin-top: $dist*2;
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;

	@include breakpoint(null, $max-width) {
		margin-top: 101px;
	}

	@include breakpoint(null, 580px) {
		margin-top: $dist;
	}

	@include breakpoint(null, $bp-s) {
		margin-top: $gutter-width;
		margin-bottom: $dist*0.5;
	}

	h2 {
		font-size: $fs-h4;
		font-family: $ff-h;
		font-weight: $fw;
		margin-bottom: $dist*0.25;

		@include breakpoint(null, $bp-m) {
			font-size: $fs-h5;
			margin-bottom: $dist*0.125;
		}
	}

	h1 {
		line-height: 1;
		font-size: $fs-h2;
		font-weight: $fw-medium;
		font-family: $ff-h;
		text-transform: uppercase;
		@extend %border-bottom-big;

		@include breakpoint(null, $bp-m) {
			font-size: $fs-h3;
		}
	}

	.breadcrumbs {
		margin-bottom: $dist*0.25;
		color: $fc-light;

		a {
			color: $fc-light;

			&:hover {
				color: $ac;
			}
		}
	}
}