#grasenhiller_appointmentpage {
	.main__text {
		& + .calendar {
			@include resp-margin(top);
		}
	}

	.calendar {
		& + form {
			@include resp-margin(top);
		}
	}

	form {

		fieldset {

			#FirstNameSurname,
			#EmailPhone {
				@extend %cf;

				.fieldgroup-field {
					width: span(6 of 12);
					float: left;

					&:first-of-type {
						margin-right: gutter();
					}
				}
			}

			.requiredField {

				label {
					&:after {
						content: "*";
						color: red;
						margin-left: $dist*0.125;
					}
				}
			}

			label {
				font-weight: $fw-medium;
				margin-bottom: $dist*0.25;
				display: block;
			}
		}
	}
}