.team--teaser {
	@extend %cf;
	position: relative;
	margin: $dist*3 0;

	@include breakpoint(null, 1820px) {
		margin: $dist*2 0;
	}

	@include breakpoint(null, $max-width) {
		width: calc(100% - (#{$dist} / 2));
		margin: $dist auto;
	}

	&_left {
		background: $fc;
		color: $page-bg;
		float: left;
		border-radius: 0 $radius $radius 0;
		width: 100%;
		padding: $dist*2 0;

		@include breakpoint (null, $max-width) {
			width: span(7 of 12);
			padding: $dist 0;
		}

		@include breakpoint(null, $bp-m) {
			width: 100%;
			border-radius: $radius $radius 0 0;
		}

		.content-wrapper{
			max-width: $max-width;
			margin: 0 auto;
			width: 100%;
			@extend %cf;

			.content-wrapper-inner {
				width: span(7 of 12);
				float: left;
				padding-right: $gutter-width*2;

				@include breakpoint(null, $max-width) {
					width: 100%;
					padding: 0 $gutter-width;
				}

				h2 {
					font-family: $ff-h;
					font-size: $fs-h2;
					font-weight: $fw-medium;
					text-transform: uppercase;
					@extend %border-bottom-big;

					&:after {
						background: $page-bg;
					}

					@include breakpoint(null, $bp-m) {
						font-size: $fs-h3;
					}
				}

				h3 {
					font-family: $ff-h;
					font-size: $fs-h4;
					font-weight: $fw;

					@include breakpoint(null, $bp-m) {
						font-size: $fs-h5;
					}
				}

				p {
					margin-top: $gutter-width;
					float: left;
					margin-right: $gutter-width;
					@extend %formatted-text;

					&:last-of-type {
						margin-right: 0;
					}

					@include breakpoint(null, $max-width) {
						margin-right: $gutter-width*0.5;
						margin-top: $gutter-width*0.5;
					}
				}

				hr {
					background: $fc-light;
					margin: $dist*0.5 0;
				}
			}
		}
	}

	&_right {
		@include center(v);
		right: 0;
		padding: $gutter-width 0 $gutter-width $gutter-width;
		background: $page-bg;

		@include breakpoint(null, 1530px) {
			padding-top: $dist*2;
			padding-bottom: $dist*2;
		}

		@include breakpoint(null, $max-width) {
			width: span(5 of 12);
			padding: 0;
		}

		@include breakpoint(null, $bp-m) {
			width: 100%;
			position: relative;
			padding: 0;
			float: left;
			transform: none;
		}

		img {
			border-radius: $radius 0 0 $radius;
			width: 100%;
			height: auto;

			@include breakpoint(null, 1820px) {
				max-width: 700px;
			}

			@include breakpoint(null, 1530px) {
				max-width: 500px;
			}

			@include breakpoint(null, $bp-m) {
				max-width: 100%;
				border-radius: 0 0 $radius $radius;
			}
		}
	}
}