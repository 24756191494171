.module--grid {
  .grid {
    @extend %cf;

    &__item {
      display: block;
      float: left;
      background: $box-color;
      border-radius: $radius;
    }

    &__header--icon {
      margin-top: -15px;
    }

    &__header {
      text-align: center;
      margin-bottom: $dist*0.5;

      img {
        display: inline-block;
        width: 100%;
        height: auto;
        border-radius: $radius $radius 0 0;
      }

      i {
        font-size: $fs-h4;
        color: $page-bg;
        width: 50px;
        height: 50px;
        border-radius: $radius;
        background: $ac-gradiant;

        &:before {
          padding-top: 12px;
          display: block;
        }
      }

      a {
        color: $fc;

        &:hover {
          color: $ac;
        }
      }
    }

    &__title {
      margin-bottom: $dist*0.5;
      padding: 0 $gutter-width;

      h4 {
        font-size: $fs-h4;
        font-family: $ff-h;
        font-weight: $fw-h;
      }
    }

    &__content {
      padding: 0 $gutter-width $gutter-width $gutter-width;
    }

    &__link {
      display: inline-block;
      margin-top: $dist*0.5;
      text-decoration: none;
    }

    &--align-center {
      text-align: center;
    }

    &--ipr-2 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, null, 2, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-3 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 3, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-4 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 4, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }
  }
}