@use "sass:math";

.team-members,
.search-results__list--TeamMember .search-results__list-items {
  @extend %cf;
  $grid-conf: (
      (null, $bp-m, 1),
      ($bp-m, null, 2)
    );
  .team-member {
    @extend %cf;
    @include resp-gallery-layout($grid-conf);

    @include breakpoint(null, $bp-m) {
      margin-bottom: $dist*0.5;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__image {
      position: relative;
      float: left;
      margin-right: $dist;
      width: calc(30% + #{$dist});
      background: $box-color;
      text-align: center;

      @include breakpoint(null, $bp-l) {
        margin-right: $dist*0.5;
        width: calc(30% - (#{$dist} / 2));
      }

      img {
        @include resp-img;
        border-radius: $radius;
      }

      &--no-image {
        @include aspect-ratio(281,140);
        border-radius: $radius;

        & > *:first-child {

          &:before {
            font-size: 2.5rem;
            @include center(f);
            color: $box-color-dark;
          }
        }
      }
    }

    &__info {
      float: left;
      width: calc(100% - (30% + (#{$dist} * 2)));
      margin-top: $dist*0.5;

      @include breakpoint(null, $bp-l) {
        width: calc(100% - (30% + #{$dist}));
        margin-top: 0;
      }

      li {
        @include truncate();
      }

      .info-line-1,
      .info-line-2 {
        white-space: normal;
        //overflow: auto;
        //text-overflow: inherit;
      }

      i {
        margin-right: 0.5em;
        color: $hc;
      }

      a {
        @extend %decent-link;
      }
    }

    &__separator {
      line-height: 1;
      height: $dist*0.5;
      border-top: 1px solid $box-color;
      margin-top: $dist*0.5;

      @include breakpoint(null, $bp-s) {
        display: none;
      }
    }

    &__title {
      font-family: $ff-h;
      @extend %border-bottom-small;
      font-size: $fs-h4;

      @include breakpoint(null, $bp-s) {
        font-size: $fs-h5;
        margin-bottom: math.div($dist, 6);

        &:after {
          display: none;
        }
      }
    }
  }
}