.page-3 {

	main {
		@extend %cf;
	}

	article {
		float: left;
		width: span(6 of 12);
		margin-right: gutter();
		background: $box-color;
		border-radius: $radius;

		@include breakpoint(null, $bp-m) {
			width: 100%;
			margin-right: 0;
		}

		p {
			padding: 0 $dist $gutter-width $dist;
			margin-top: 0;
			width: 100%;

			@include breakpoint(null, $bp-s) {
				padding: 0 $gutter-width $gutter-width $gutter-width;
			}

			&:first-of-type {
				padding-top: $gutter-width;
			}

			&:last-of-type {
				padding: 0;
			}

			a {
				text-decoration: none !important;
				color: $fc !important;

				&:hover {
					background: none !important;
					text-decoration: underline !important;
				}
			}
		}
	}

	form {
		float: left;
		width: span(6 of 12);
		clear: none;
		margin-top: 0 !important;

		@include breakpoint(null, $bp-m) {
			width: 100%;
			margin-top: $dist !important;
		}
	}
}