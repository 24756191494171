#grasenhiller_homepage {

	.page-title {
		@extend %outer-container;
	}

	.main__text {
		@extend %outer-container;
	}

	.homepage--carrer {
		.module__title {

			h3 {
				font-size: $fs-h2;

				@include breakpoint(null, $bp-m) {
					font-size: $fs-h3;
				}

			}
		}
	}
}