html .basic-holder {
  .basic-holder__text {
    & + .basic-holder__children {
      @include resp-margin(top);
      @include resp-padding(top);
      border-top: 1px solid $box-color;
    }
  }

  &__children {}

  &__child {
    @include resp-margin(top);

    &:first-of-type {
      margin-top: 0;

    }

    & > h3 {
      display: block;
      @include resp-margin(bottom, 0.5);

      a {
        color: $fc;

        &:hover {
          color: $ac;
        }
      }
    }

    & > p {
      margin: 0;
    }
  }

  &__grand-children {
    @extend %cf;
    margin: 0 !important;
    @include resp-margin(top, 0.25, $bp-s);

    li {
      display: block;

      @include breakpoint($bp-m, null) {
        float: left;
        width: calc(50% - #{$dist*0.25});

        &:nth-of-type(2n+2) {
          margin-left: $dist*0.5;
        }

        &:nth-of-type(2n+3) {
          clear: both;
        }
      }
    }
  }

  &__pagination {
    @include resp-margin(top);
  }
}
