.service-preview {
	@extend %cf;
	@include resp-margin(top, 1, $bp-s);
	float: left;

	&__image {
		float: left;

		a {
			@include image-hover($fa-var-long-arrow-right);
			border-radius: $radius;
		}

		img {
			@include resp-img;
			border-radius: $radius;
		}
	}

	&__text {
		float: left;

		h2 {
			font-size: $fs-h4;
			@extend %border-bottom-small;

			a {
				text-decoration: none !important;
				color: $fc !important;
				font-weight: $fw-h;

				&:hover {
					background: none !important;
					text-decoration: underline !important;
				}
			}
		}
	}

	@include breakpoint($bp-m, null) {
		margin-right: gutter(12);
	}

	@include breakpoint(null, $bp-m) {
		margin-right: 0;
	}
}