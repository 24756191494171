@use "sass:math";

.page-slider {

  @include breakpoint(null, $max-width) {
    margin-top: 87px;
  }
  @include breakpoint(null, $bp-s) {
    margin-top: 70px;
  }

  @include breakpoint(null, $bp-xs) {
    margin-top: 66px;
  }

  .slick-arrow {
    @include center(v);
    z-index: 20;
    display: block;
    color: #ffffff;
    font-size: $fs-h4;

    &:hover {
      color: $ac;
    }

    &.fa-chevron-left {
      left: $dist*0.5;
    }

    &.fa-chevron-right {
      right: $dist*0.5;
    }
  }

  .slick-dots {
    position: absolute;
    z-index: 20;
    bottom: $dist*0.5;
    left: 0;
    right: 0;
    font-size: 0;
    text-align: center;

    li {
      display: inline-block;
      margin-right: 2px;

      &:last-of-type {
        margin-right: 0;

        &:first-of-type {
          display: none;
        }
      }

      button {
        cursor: pointer;
        display: block;
        width: math.div($dist, 3);
        height: math.div($dist, 3);
        background: $box-color;
        border-radius: 100%;
        font-size: 0;
        border: 0;
        box-shadow: none;

        &:hover {
          background: $box-color-dark;
        }
      }

      &.slick-active {
        button {
          background: $hc;

          &:hover {
            background: darken($hc, 10%);
          }
        }
      }
    }
  }

  &__slide {
    position: relative;
    background: $box-color-dark;

    img {
      display: block;
      width: 100%;
      height: auto;
      opacity: 0.7 !important;

    }
  }

  &__caption {
    z-index: 10;
    position: absolute;
    transform: translateY(-50%);
    top: calc(50% + 30px);
    left: 0;
    right: 0;

    @include breakpoint(null, $max-width) {
      top: 50%;
    }

    @include breakpoint(null, $max-width) {
      width: calc(100% - #{$dist});
      margin: 0 $dist*0.5;
    }

    @include breakpoint(null, $bp-s) {
      display: none;
    }
  }

  &__caption-content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $max-width;

    & > h3 {
      font-size: $fs-h1;
      font-weight: $fw-h-bold;
      text-transform: uppercase;
      font-family: $ff-h;
      color: #ffffff;
      text-shadow: 2px 2px 0px rgba($fc, 0.3);

      @include breakpoint(null, $bp-nav) {
        font-size: $fs-h2;
      };

      @include breakpoint(null, 650px) {
        font-size: $fs-h3;
      }
    }

    & > p {
      margin-top: $dist*0.5;
      font-size: $fs-h5;
      color: #ffffff;
      max-width: math.div($max-width, 1.5);
      text-shadow: 2px 2px 0px rgba($fc, 0.3);

      @include breakpoint(null, $bp-nav) {
        font-size: $fs;
        max-width: 100%;
        margin-top: $dist*0.25;
      }

      @include breakpoint(null, 580px) {
        display: none;
      }

      span {
        box-decoration-break: clone;
      }
    }

    & > a {
      margin-top: math.div($dist, 1.25);
      display: inline-block;
      color: $page-bg;
      @extend %button;
      @include icon-before($fa-var-long-arrow-right);
      padding-left: $dist;

      &:before {
        font-weight: $fw-light;
      }

      @include breakpoint(null, $bp-nav) {
        margin-top: $dist*0.5;
      }

      @include breakpoint(null, 650px) {
        padding: 10px;

        &:before {
          display: none;
        }
      }
    }

    &.page-slider__caption-content--font-color-light {
      & > h3,
      & > p,
      & > a {
        color: #ffffff;
      }
    }
  }
}