@use "sass:math";

#calendar {
	.fc-event, .fc-event-dot {
		background: $ac;
		border-color: $ac;
	}

	.fc-time,
	.fc-title {
		font-size: $fs-small;
		padding: math.div($dist, 6);
	}

	.fc-time {
		font-weight: $fw-bold;
		padding-bottom: 0 !important;
	}

	.fc-today {
		background: none !important;
	}

	.fc-nonbusiness {
		background: #a0a0a0;
		cursor: not-allowed;
	}

	.fc-toolbar {
		.fc-left {
			.fc-prev-button,
			.fc-next-button {
				background: $fc;
				border: none;
			}

			.fc-today-button {
				border: none;
				background: $fc;
			}

			.fc-button:disabled {
				background: darken($box-color, 25%);
				opacity: 1 !important;
			}
		}
	}
}