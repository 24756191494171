#grasenhiller_referenceholder.page--no-banner {
	.page-title {
		margin-top: $dist*4.5;
		@extend %outer-container;
	}
	.reference-holder {
		margin-top: $gutter-width*2;
	}
}

#grasenhiller_referenceholder {

	.page-title {
	    @extend %outer-container;
	}
}

.reference-list {
	position: relative;
	margin-top: $dist*1.5;

	@include breakpoint (null, $bp-m) {
		margin-top: $gutter-width;
	}
}