@use "sass:math";

.sticky-toolbar {
	position: fixed;
	z-index: 999;
	right: 0;
	top: 50%;
	transform: rotate(-90deg) translateY(-100%) translateX(50%);
	transform-origin: top right;

	&_links {
		@extend %cf;

		a {
			border-radius: $radius $radius 0 0;
			cursor: pointer;
			@extend %button;
			white-space: nowrap;
			display: block;
			float: left;

			@include breakpoint(null, $bp-s) {
				padding: $dist*0.25 $gutter-width*0.5;
			}

			&:first-of-type {
				@include breakpoint(null, $bp-s) {
					display: none;
				}

				& + a {
					margin-left: math.div($dist, 6);
				}
			}

			&:last-of-type {
				background: linear-gradient(45deg, #F09900 0%,#F55200 100%);

				&:hover {
					background: linear-gradient(45deg, #BD7800 0%, #C24100 100%) !important;
				}
			}
		}
	}
}