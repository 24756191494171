#grasenhiller_bathshowroompage.page--no-banner {
	.page-title {
		@extend %outer-container;
		margin-top: $dist*4.5;

		@include breakpoint(null, $max-width) {
			margin-top: $dist*3.5;
		}

		@include breakpoint(null, 580px) {
			margin-top: 132px;
		}

		@include breakpoint(null, $bp-s) {
			margin-top: 105px;
		}

		@include breakpoint(null, $bp-xs) {
			margin-top: 95px;
		}
	}
}

#grasenhiller_bathshowroompage {
	.page-title {
		@extend %outer-container;
		margin-top: $dist*2.5;

		@include breakpoint(null, $max-width) {
			margin-top: 101px;
		}

		@include breakpoint(null, 580px) {
			margin-top: $dist;
		}

		@include breakpoint(null, $bp-s) {
			margin-top: $gutter-width;
			margin-bottom: $dist*0.5;
		}
	}
}

.bath-showroom {

	&__top {
		@extend %cf;
		@extend %outer-container;
		position: relative;
		z-index: 10;

		img {
			border-radius: $radius;
			width: 100%;
			height: auto;
		}

		.left {
			width: span(5 of 12);
			float: left;
			margin-right: gutter();

			@include breakpoint(null, $bp-s) {
				width: 100%;
				margin-right: 0;
			}

			img {
				margin-top: $gutter-width;
			}
		}

		.right {
			width: span(7 of 12);
			float: left;

			@include breakpoint(null, $bp-s) {
				width: 100%;
				margin-top: $gutter-width;
			}

			.bath-showroom__quote {
				margin-top: $gutter-width*2;
				font-size: $fs-h4;
				font-family: $ff-h;
				font-style: italic;
				@extend %border-bottom-big
			}

			img {
				box-shadow: $dist*0.5 $dist*0.5 0px 0px rgba(228,235,241,1);
			}
		}
	}

	&__middle {
		margin-top: $dist*2;
		position: relative;
		z-index: 9;

		@include breakpoint(null, $bp-m) {
			margin-top: $dist;
		}

		.bath-showroom__middle-background {
			width: 40%;
			height: 300px;
			background: $box-color;
			position: absolute;
			top: -300px;

			@include breakpoint(null, $bp-l) {
				width: 60%;
			}

			@include breakpoint(null, $bp-s) {
				display: none;
			}
		}

		.bath-showroom__middle-slider {
			position: relative;

			.slick-prev {
				@include center(v);
				z-index: 999;
				font-size: 0;
				left: $dist;
				padding: 18px 19px;
				cursor: pointer;

				&:before {
					@include fontawesome($fa-var-long-arrow-left);
					font-size: $fs-h4;
				}

				@include breakpoint(null, $bp-m) {
					padding: 10px 11px;
					left: 0;
					border-radius: 0 $radius $radius 0;
				}
			}

			.slick-next {
				@include center(v);
				z-index: 999;
				font-size: 0;
				right: $dist;
				padding: 18px 19px;
				cursor: pointer;

				&:before {
					@include fontawesome($fa-var-long-arrow-right);
					font-size: $fs-h4;
				}

				@include breakpoint(null, $bp-m) {
					padding: 10px 11px;
					right: 0;
					border-radius: $radius 0 0 $radius;
				}
			}
		}
	}

	&__bottom {
		@extend %outer-container;
		margin-top: $dist*2;

		@include breakpoint(null, $bp-m) {
			margin-top: $dist;
		}

		.modular-content {

			.textimage--image-right {

				img {
					height: auto;
					margin-right: gutter(12);
				}
			}

			.textimage--image-left {

				img {
					float: left;
					width: 100%;
					height: auto;
					margin-right: gutter(12);
				}
			}

			.textimage--image-left,
			.textimage--image-right {

				.textimage__image {
					@include breakpoint($bp-s, null) {
						display: block;
						width: 100%;
						height: auto;
						max-width: span(5 of 12);
					}
				}

				.formatted-text {
					@include breakpoint($bp-s, null) {
						width: span(7 of 12);
						float: left;
					}

					a {
						float: left;
						&:after,
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
}