.navigation {
	position: relative;
	z-index: 500;

	// - default
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			line-height: 1;
			font-size: $fs;

			&[class*="--current"],
			&[class*="--section"],
			&:hover {
				& > a {
					color: $ac;
				}
			}

			a {
				display: block;
				color: $fc;
				white-space: nowrap;
				text-decoration: none;
				line-height: 1;
			}
		}
	}

	.navigation__sub {
		display: block;
		position: absolute;
		opacity: 0;
		visibility: hidden;
	}

	// - vertical navigation
	&--vertical {
		.navigation__sub {
			transform: translateX(100%);
			top: 0;
			right: 0;
		}

		ul {
			li {
				&:hover {
					& > .navigation__sub {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	// - horizontal navigation
	&--horizontal {
		.navigation__top {
			font-size: 0;
		}

		.navigation__top {
			display: flex;
			justify-content: space-between;
		}

		.navigation__top-link {
			&:hover {
				& > .navigation__sub {
					opacity: 1;
					visibility: visible;
					top: $fs;
					background: $box-color;
					padding: $gutter-width*0.5;

					li {
						border-bottom: 1px solid $page-bg;
						padding-bottom: $dist*0.25;
						margin-bottom: $dist*0.25;

						&:last-of-type {
							border-bottom: none;
							padding-bottom: 0;
							margin-bottom: 0;
						}

						a {
							&:hover {
								border-bottom: 0;
								padding-bottom: 0;
							}
						}
					}
				}
			}

			& > .navigation__sub {
				right: 0;

			}

			.navigation__sub {
				min-width: 100%;

				.navigation__sub {
					transform: translateX(100%);
					top: 0;
					right: 0;
				}

				li {
					&:hover {
						& > .navigation__sub {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			&:nth-last-of-type(-n+2) {
				& > .navigation__sub {
					right: 0;
					left: auto;

					.navigation__sub {
						transform: translateX(-100%);
						right: 0;
						left: auto;
					}
				}
			}
		}
	}

	.mobile-navigation-button {
		display: none;
		cursor: pointer;
		font-size: $fs-h4;
		color: $fc;

		&:hover {
			color: $ac;
		}
	}

	&--main {

		.navigation__top-link {
			font-size: $fs;

			a {
				&:hover {
					color: $hc;
					border-bottom: 1px solid $hc;
					padding-bottom: 13px;
				}
			}
		}

		&.navigation--horizontal {
			.navigation__top-link {
				&:hover {
					& > .navigation__sub {
						top: $fs-h4;
					}
				}
			}
		}

		@include breakpoint(null, $bp-nav) {
			.mobile-navigation-button {
				display: block;
				text-align: right;

				i {
					padding: 8px 10px;
					border: 1px solid $box-color;
				}
			}

			.navigation__top {
				display: none;
			}
		}

		@include breakpoint(null, $bp-s) {
			.mobile-navigation-button {

				i {
					padding: 7px 9px;
				}
			}
		}

		@include breakpoint(null, $bp-xs) {
			.mobile-navigation-button {

				i {
					padding: 5px 7px;
				}
			}
		}
	}

	&--footer {
		ul {
			li {
				margin-right: $dist*0.5;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	&--mobile {
		// - overwrite defaults
		ul {
			li {
				@extend %cf;

				&:hover {
					& > .navigation__sub {
						display: none;
						transform: translateX(0);
					}
				}
			}
		}

		.navigation__sub {
			clear: both;
			position: static !important;
			display: none;
			border-top: 1px solid $page-bg;
			opacity: 1 !important;
			visibility: visible !important;
			transform: none !important;

			&--active {
				display: block !important;
			}

			.navigation__sub-link {
				@extend %cf;
				border-bottom: 1px solid $page-bg;

				&--has-sub {
					& > a {
						float: left;
						width: calc(100% - 1em - #{$dist*0.5});
					}

					& > .navigation__sub-toggle {
						padding: $dist*0.25;
						width: calc(1em + #{$dist*0.5});
					}
				}

				&:last-of-type {
					border-bottom: 0;
				}
			}
		}

		.navigation__sub-toggle {
			display: block;
			float: left;
			cursor: pointer;
			color: $fc-light;
			line-height: 1;

			&:hover,
			&--active {
				color: $fc;
			}

			&--active {
				i:before {
					content: fa-content($fa-var-minus-circle);
				}
			}
		}

		// - mobile navigation styling | level 1
		.navigation__top-link {
			border-bottom: 1px solid $page-bg;

			& > a {
				padding: $dist*0.5;
				font-weight: $fw-bold;
			}

			&--has-sub {
				& > a {
					float: left;
					width: calc(100% - 1em - #{$dist*0.5});
				}
			}

			& > .navigation__sub-toggle {
				padding: $dist*0.5 $dist*0.25;
				width: calc(1em + #{$dist*0.5});
			}

			// - level 2
			& > .navigation__sub {
				a {
					padding: $dist*0.25 $dist*0.5;
				}

				& > li {
					// - level 3
					& > .navigation__sub {
						& > li {
							& > a {
								background: darken($page-bg, 5%);
							}

							& > .navigation__sub-toggle {
								background: darken($page-bg, 5%);
							}

							// - level 4
							& > .navigation__sub {
								& > li {
									& > a {
										background: darken($page-bg, 10%);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}