.module--textimage {
	.module__inner {
		.textimage__image a,
		a.textimage__image {
			display: block;

			&[data-lightbox] {
				@include image-hover();
			}

			&:not([data-lightbox]) {
				@include image-hover($fa-var-arrow-right);
			}

			img {
				display: block;
				width: 100%;
				height: auto;
				border-radius: $radius;
			}
		}
	}

	.textimage {
		@extend %cf;

		&--image-top {
			.textimage__image {
				@include resp-margin(bottom);
				border-radius: $radius;
			}
		}

		&--image-bottom {
			.textimage__image {
				@include resp-margin(top);
				border-radius: $radius;
			}
		}

		.formatted-text {
			span {
				font-size: $fs-h4;
				font-family: $ff-h;
			}

			.textimage__title {
				margin-top: 0 !important;
				font-weight: $fw-h-medium;
				text-transform: uppercase;

				&:after {
					margin-top: $dist*0.25;
				}
			}
		}

		&--image-left,
		&--image-right {
			.textimage__image {
				border-radius: $radius;
				width: span(3 of 12);

				img {
					display: block;
					width: 100%;
					height: auto;
					border-radius: $radius;
				}
			}

			.formatted-text {
				width: span(9 of 12);
				float: left;
			}

			@include breakpoint(null, $bp-s) {
				.textimage__image {
					float: none !important;
					margin: 0;
					width: 100%;

					& + .formatted-text {
						margin-top: $dist*0.5 !important;
					}
				}

				.formatted-text {
					float: none;
					margin: 0;
					width: 100%;

					& + .textimage__image {
						margin-top: $dist*0.5 !important;
					}
				}
			}

			@include breakpoint($bp-s, $bp-m) {
				.textimage__image {
					width: span(6 of 12);
				}

				.formatted-text {
					width: span(6 of 12);
				}
			}
		}

		&--image-left {
			.textimage__image {
				float: left;
				margin-right: gutter(12);
			}
		}

		&--image-right {
			.textimage__image {
				float: right;
				margin-left: gutter(12);
			}
		}

		&--text-floating {
			.formatted-text {
				width: 100%;

				p {
					overflow: visible;
				}
			}

			.textimage__image {
				display: block;
				float: left;
				margin-bottom: $dist*0.25;

				@include breakpoint(null, $bp-s) {
					margin: 0 !important;
					margin-bottom: $dist*0.5 !important;
				}
			}

			&.textimage--image-right {
				.textimage__image {
					float: right;
				}
			}
		}
	}
}