.homepage--carrer {
	@extend %outer-container;
	@extend %cf;

	&_block {
		float: right;
		background: $box-color;
		padding: $gutter-width $dist $dist*1.25 $dist;
		border-radius: $radius;
		width: span(3 of 12);
		position: relative;

		@include breakpoint(null, $bp-l) {
			width: 100%
		}

		@include breakpoint(null, $bp-s) {
			padding: $gutter-width $gutter-width $dist *1.25 $gutter-width;
		}

		h3 {
			font-family: $ff-h;
			font-size: $fs-h4;
			@extend %border-bottom-small;
			font-weight: $fw;
		}

		.phone {
			border-top: 1px solid $page-bg;
			padding-top: $gutter-width*0.5;
			margin-top: $gutter-width*0.5;
			@include icon-before($fa-var-phone);
		}

		.fax {
			@include icon-before($fa-var-print);
		}

		.button {
			position: absolute;
			bottom: -$dist*0.5;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
			width: calc(100% - (2 * #{$dist}));

			@include breakpoint(null, $bp-s) {
				width: calc(100% - (2 * #{$gutter-width}));
			}
		}
	}

	.modular-content {
		float: left;
		width: span(9 of 12);

		@include breakpoint(null, $bp-l) {
			width: 100%;
			margin-bottom: $dist;
		}

		@include breakpoint(null, $bp-s) {
			margin-bottom: $dist*0.5;
		}
	}
}