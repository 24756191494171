.reference-detail {

	.content {
		margin-bottom: $dist;

		@include breakpoint(null, $bp-m) {
			margin-bottom: $gutter-width;
		}
	}

	&__top {
		@extend %cf;
		margin-bottom: $dist;

		@include breakpoint(null, $bp-m) {
			margin-bottom: $gutter-width;
		}

		img {
			width: 100%;
			height: auto;
			border-radius: $radius;
			display: block;
		}

		.left {
			float: left;
			width: span(6 of 12);
			margin-right: gutter();

			@include breakpoint(null, 580px) {
				width: 100%;
				margin-right: 0;
			}

			img {
				margin-bottom: $gutter-width;

				@include breakpoint(null, $bp-m) {
					margin-bottom: gutter();
				}
			}

			.bottom {
				img {
					width: calc((100% - #{$gutter-width}) / 2);
					float: left;

					@include breakpoint(null, $bp-m) {
						width: span(6 of 12);
					}

					&:first-of-type {
						margin-right: $gutter-width;

						@include breakpoint(null, $bp-m) {
							margin-right: gutter();
						}
					}
				}
			}
		}

		.right {
			float: left;
			width: span(6 of 12);

			@include breakpoint(null, 580px) {
				width: 100%;
			}

			img {
				margin-bottom: $gutter-width;

				@include breakpoint(null, $bp-m) {
					margin-bottom: gutter();
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

	&__bottom {
		@extend %cf;
		width: 100%;
		border-top: 1px solid $box-color;
		padding-top: $dist;
		margin-top: $dist;

		.contact-form {
			width: span(8 of 12);
			float: left;
			margin-right: gutter();

			@include breakpoint(null, $bp-m) {
				width: 100%;
				margin-right: 0;
			}

			#UserForm_Form_10 {

				.userform-fields {

					.form-step {
						@extend %cf;
						div {
							width: calc((100% - 31px) / 2);
							margin-right: $gutter-width;
							float: left;

							@include breakpoint(null, $bp-s) {
								width: 100%;
								margin-right: 0;
							}

							&:nth-child(2n) {
								margin-right: 0;
							}

							div {
								width: 100%;
								margin: 0 !important;
							}
						}

						.textarea,
						.checkbox {
							width: 100% !important;
						}

						.checkbox {
							label {
								float: left;
							}
						}
					}
				}
			}
		}

		.contact-form-block {
			width: span(4 of 12);
			float: left;
			border-radius: $radius;
			background: $box-color;
			padding: $dist;

			@include breakpoint(null, $bp-m) {
				width: 100%;
				margin-top: $gutter-width;
			}

			@include breakpoint(null, $bp-s) {
				padding: $gutter-width;
			}

			span {
				font-size: $fs-h4;
				font-family: $ff-h;
				font-weight: $fw;
				@extend %border-bottom-small;
			}

			ul {
				.phone {
					@include icon-before($fa-var-phone);
				}

				.fax {
					@include icon-before($fa-var-print);
				}

				.email {
					@include icon-before($fa-var-paper-plane);
				}
			}
		}
	}
}