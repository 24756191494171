@use "sass:math";

.service-page {
	&__preview-text {
		@include resp-margin(bottom);

		p {
			font-size: $fs-h4;
		}
	}

	&__text {
		@extend %cf;

		p {
			overflow: visible;

			&:first-of-type {
				margin-top: 0;
			}
		}
	}

	&__image {
		display: block;
		width: span(4 of 12);
		float: right;
		margin-left: $dist*0.5;
		margin-bottom: $dist*0.25;
		border-radius: $radius;
		@include image-hover();

		img {
			display: block;
			width: 100%;
			height: auto;
		}

		@include breakpoint(null, $bp-s) {
			width: 100%;
			margin-left: 0;
			float: none;
		}
	}

	&__media {
		margin-top: $dist;

		.page-media__images {
			@extend %cf;

			$grid-conf: (
				(null, $bp-s, 2),
				($bp-s, $bp-m, 3),
				($bp-m, $bp-l, 4),
				($bp-l, null, 6)
			);

			li {
				@include resp-gallery-layout($grid-conf);

				a {
					border-radius: $radius;
					@include image-hover();

					img {
						width: 100%;
						height: auto;
						border-radius: $radius;
					}
				}
			}
		}

		.page-media__files {
			clear: both;
			padding-top: $dist;

			li {
				width: 100%;
				border-bottom: 1px dashed $box-color;
				padding-bottom: $dist*0.25;
				margin-bottom: $dist*0.25;
				font-size: $fs-h5;
				@extend %cf;

				@include breakpoint(null, $bp-m) {
					font-size: $fs-h6;
				}

				&:last-of-type {
					border-bottom: none;
					margin-bottom: 0;
					padding-bottom: 0;
				}

				a {
					float: left;

					i {
						padding-right: math.div($dist, 3);
						color: $hc;
						font-size: $fs-h4;
					}
				}

				span {
					float: right;
				}
			}
		}

		&:not(.service-page__media--both) {
			iframe {
				width: span(6 of 12);
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&__media--both {
		@extend %cf;

		@include breakpoint($bp-m, null) {
			iframe,
			.page-media__images {
				float: left;
				width: span(6 of 12);
			}

			iframe {
				margin-right: gutter(12);
			}
		}

		.page-media__images {
			$grid-conf: (
				(null, $bp-s, 2),
				($bp-s, null, 3)
			);

			li {
				@include resp-gallery-layout($grid-conf);
				margin-bottom: gutter();
			}
		}

		@include breakpoint(null, $bp-m) {
			.page-media__images {
				margin-top: $dist;
			}
		}
	}

	&__contact {
		margin-top: $dist;

		&--both {
			@extend %cf;

			@include breakpoint($bp-m, null) {
				form,
				.service-page__contact-person {
					float: left;
					width: span(6 of 12);
				}

				.service-page__contact-person {
					margin-right: gutter(12);
				}

				form {
					clear: none !important;
				}
			}

			@include breakpoint(null, $bp-m) {
				form {
					margin-top: $dist;
				}
			}
		}
	}

	&__contact-person {
		width: span(6 of 12);

		& > strong {
			display: block;
			margin-bottom: $dist*0.5;
		}

		.team-member {
			@extend %cf;
			background: $box-color;
			padding: $gutter-width;
			border-radius: $radius;

			@include breakpoint(null, $bp-s) {
				padding: 0;
				background: none;
			}

			&__image {
				float: left;
				width: span(2 of 6);
				margin-right: gutter(6);

				img {
					@include resp-img;
					border-radius: $radius;
				}
			}

			&__info {
				float: left;
				width: span(4 of 6);

				.team-member__title {
					font-size: $fs-h4;
					font-family: $ff-h;
					@extend %border-bottom-small;

					@include breakpoint(null, $bp-s) {
						font-size: $fs-h5;
						margin-bottom: math.div($dist, 6);

						&:after {
							display: none;
						}
					}
				}

				i {
					color: $hc;
				}

				.team-member__separator {
					line-height: 1;
					height: $dist*0.25;
					margin-top: $dist*0.25;

					@include breakpoint(null, $bp-s) {
						display: none;
					}
				}
			}
		}

		@include breakpoint(null, $bp-m) {
			width: 100%;

			.team-member {

				&__image {
					width: span(4 of 12);
					margin-right: gutter(12);
				}

				&__info {
					width: span(8 of 12);
				}
			}
		}

		@include breakpoint(null, $bp-xs) {
			.team-member {

				&__image {
					width: 100%;
					@include resp-margin(v);
				}
			}
		}
	}
}


