// - Scrollbar hider
.mobile-navigation-scrollbarhider {
  display: none;

  &--force-deactived {
    display: none !important;
  }
}

@media (max-resolution: 1dppx) {
  .mobile-navigation--force-active + .mobile-navigation-scrollbarhider {
    content: '';
    display: block;
    position: fixed;
    top: -10px;
    bottom: -10px;
    right: $mobilenav-width - 2px;
    width: 17px;
    background: $page-bg;
    z-index: 9999;
  }
}

// - Offcanvas navigation
.mobile-navigation {
  position: fixed;
  overflow-y: scroll;
  z-index: 9998;
  top: 0;
  bottom: 0;
  width: $mobilenav-width;
  transition: right $duration ease;
  right: -$mobilenav-width;
  background: $box-color;

  @include breakpoint(null, $bp-nav) {
    &--active,
    &--force-active {
      right: 0;
      margin-right: 0;
    }

    &:not(.mobile-navigation--active) {
      right: -$mobilenav-width !important;
    }
  }

  @media (max-resolution: 1dppx) {
    width: $mobilenav-width + 17px;
    margin-right: -17px;

    &--active,
    &--force-active {
      margin-right: -17px;
    }
  }
}

.mobile-navigation-overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;
  opacity: 0;
  visibility: hidden;
  background: $box-color-dark;
  transition: all $duration ease;

  &--active {
    @include breakpoint(null, $bp-nav) {
      opacity: 0.8;
      visibility: visible;
    }
  }

  &--force-active {
    opacity: 0.8;
    visibility: visible;
  }
}
