.footer {
  background: $box-color-dark;
  color: $page-bg;
  @include resp-margin(top, 2);

  &--inner-wrapper {
    @extend %cf;
    max-width: $max-width;
    width: calc(100% - (#{$dist} / 2));
    margin: 0 auto;

    a {
      color: $page-bg !important;
    }

    &_bottom {
      padding: $dist*0.5 0;
      border-top: 1px solid $fc;
      @extend %cf;

      @include breakpoint($bp-m, null) {
        span {
          float: left;
        }

        ul {
          float: right;
        }
      }

      @include breakpoint(null, $bp-m) {
        text-align: center;

        span {
          margin-bottom: $dist*0.25;
          display: inline-block;
        }
      }
    }

    &_top {
      @include resp-padding(top, 2);
      @include resp-padding(bottom, 2);
      @extend %cf;

      $grid-conf: ((null, $bp-m, 1) ($bp-m, null, 3));

      .footer--box {
        @include resp-gallery-layout($grid-conf);

        span {
          font-size: $fs-h4;
          font-family: $ff-h;
          line-height: 0;
          @extend %border-bottom-small;

          &:after {
            background: $fc;
          }
        }

        @include breakpoint(null, $bp-m) {
          margin-bottom: $dist*0.5;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        li {
          margin-bottom: $dist*0.125;
          &:last-of-type {
            margin-bottom: 0;
          }

          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }

        &:first-of-type {
          .phone {
            @include icon-before($fa-var-phone);
          }

          .fax {
            @include icon-before($fa-var-print);
          }

          .email {
            @include icon-before($fa-var-paper-plane);
          }
        }

        &:last-of-type {
          .navigation--main {
            ul {
              display: block !important;
              @extend %cf;

              li {
                width: 50%;
                float: left;
                margin-bottom: $dist*0.2;

                a {
                  border-bottom: 0 !important;
                  padding-bottom: 0;
                }
              }
            }

            .mobile-navigation-button {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
