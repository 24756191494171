@import "../../../node_modules/susy/sass/_susy.scss";
@import "../../../vendor/grasenhiller/silverstripe-tweaks/client/libraries/fontawesome/scss/_variables";
@import "globals/_variables";
@import "globals/_extends";
@import "globals/_mixins";
@import "globals/_text-styling";

// - Set box-sizing to border-box
*,
*:after,
*:before {
	box-sizing: border-box;
}

// - Fix small gutters of images
img {
	vertical-align: top;
}

// - Remove focus
*:focus {
	outline: 0;
}

// - IE + Edge ... whoop whoop
main,
.main {
	display: block;
}

// - Set page bg color
html {
	background: $box-color-dark;
}

body {
	background: $page-bg;
}

.header-wrapper {
	position: relative;
}

.outer-wrapper {
	@extend %outer-container;
	margin-top: $dist*2.5;
}

.page--no-banner {

	.outer-wrapper {
		margin-top: $dist*4;

		@include breakpoint(null, $max-width) {
			margin-top: $dist*3.5;
		}
	}
}

$bp-columns: 1020px;

.modular-content {

	.module {
		position: relative;
		@include resp-margin(bottom);

		&:hover {
			.module__editlink {
				opacity: 0.625;
			}
		}

		&__editlink {
			opacity: 0.25;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.5em 1em;
			color: #ffffff;
			border-radius: 5px;
			background: $ac;
			transition: opacity $duration linear, background $duration linear;

			i {
				margin-right: 0.5em;
			}

			&:hover {
				opacity: 1 !important;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&--separator-top {
			@include resp-padding(top);
			border-top: 1px solid $border-color;

			.module__editlink {
				top: $dist;

				@include breakpoint(null, $bp-m) {
					top: $dist*0.5;
				}
			}
		}

		&--separator-bottom {
			@include resp-padding(bottom);
			border-bottom: 1px solid $border-color;
		}

		&:first-of-type {
			&.module--separator-top {
				padding-top: 0;
				border-top: 0;
			}
		}

		&:last-of-type {
			margin-bottom: 0;

			&.module--separator-bottom {
				padding-bottom: 0;
				border-bottom: 0;
			}
		}

		&__title {
			@include resp-margin(bottom, 0.5);
			font-family: $ff-h;

			strong {
				font-weight: $fw-h;
				font-size: $fs-h4;

				@include breakpoint(null, $bp-m) {
					font-size: $fs-h5;
					margin-bottom: $dist*0.125;
				}
			}

			h3 {
				font-size: $fs-h3;
				font-weight: $fw-medium;
				text-transform: uppercase;

				@include breakpoint(null, $bp-m) {
					font-size: $fs-h3;
				}
			}
		}
	}

	.modular-content-columns {
		@extend %cf;
		@include resp-margin(bottom);

		.modular-content-column {
			opacity: 1;
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}
	
@import "modular-content-partials/_accordion.scss";
@import "modular-content-partials/_column.scss";
@import "modular-content-partials/_downloads.scss";
@import "modular-content-partials/_gallery.scss";
@import "modular-content-partials/_grid.scss";
@import "modular-content-partials/_headline.scss";
@import "modular-content-partials/_textimage.scss";
}

@import "partials/_address-bar.scss";
@import "partials/_basic-holder.scss";
@import "partials/_calendar.scss";
@import "partials/_carrer.scss";
@import "partials/_contact-page.scss";
@import "partials/_contact-person.scss";
@import "partials/_cookie-consent.scss";
@import "partials/_footer.scss";
@import "partials/_form.scss";
@import "partials/_header.scss";
@import "partials/_loader.scss";
@import "partials/_mobile-navigation.scss";
@import "partials/_navigation.scss";
@import "partials/_page-slider.scss";
@import "partials/_page-title.scss";
@import "partials/_pagination.scss";
@import "partials/_reference-holder.scss";
@import "partials/_reference-page.scss";
@import "partials/_reference.scss";
@import "partials/_service-preview.scss";
@import "partials/_team-member.scss";
@import "partials/_team-teaser.scss";
@import "partials/_teaser-box.scss";
@import "partials/_toolbar.scss";
@import "partials/_totop.scss";
@import "partials/_userforms.scss";
@import "pages/_appointmentpage.scss";
@import "pages/_bathroompage.scss";
@import "pages/_homepage.scss";
@import "pages/_service-holder.scss";
@import "pages/_service-page.scss";
@import "pages/_team-page.scss";
