.service-holder__children {
	@extend %cf;

	@mixin service-preview-ipr-2($startBP, $endBP) {
		@include breakpoint($startBP, $endBP) {
			width: span(6 of 12);

			&:nth-of-type(2n+2) {
				margin-right: 0;
			}

			&:nth-of-type(2n+3) {
				clear: both;
			}

			&:nth-of-type(-n+2) {
				margin-top: 0;
			}

			&__image {
				width: span(2 of 6);
				margin-right: gutter(6);
			}

			&__text {
				width: span(4 of 6);
			}
		}

		@include breakpoint(null, $startBP) {
			width: 100%;

			&:first-of-type {
				margin-top: 0;
			}

			&__image {
				width: span(4 of 12);
				margin-right: gutter(12);
			}

			&__text {
				width: span(8 of 12);
			}
		}

		@include breakpoint(null, $bp-xs) {

			 &__image {
				 width: 100%;
				 float: none;
				 margin-bottom: $dist*0.5;
			 }

			&__text {
				width: 100%;
			}
		 }
	}

	&--ipr-1 {
		.service-preview {
			@include service-preview-ipr-2($bp-m, $bp-l);

			@include breakpoint($bp-l, null) {

				&__image {
					width: span(3 of 12);
					margin-right: gutter(12);
				}

				&__text {
					width: span(9 of 12);
				}
			}
		}
	}

	&--ipr-2 {
		.service-preview {
			@include service-preview-ipr-2($bp-m, null);
		}
	}

	&--ipr-3 {
		.service-preview {
			@include breakpoint($bp-l, null) {
				width: span(4 of 12);

				&:nth-of-type(3n+3) {
					margin-right: 0;
				}

				&:nth-of-type(3n+4) {
					clear: both;
				}

				&:nth-of-type(-n+3) {
					margin-top: 0;
				}

				&__image {
					float: none;
					margin-bottom: $dist*0.5;
				}

				&__text {
					float: none;
				}
			}

			@include breakpoint(null, $bp-m) {
				width: 100%;

				&:first-of-type {
					margin-top: 0;
				}

				&__image {
					width: span(4 of 12);
					margin-right: gutter(12);
				}

				&__text {
					width: span(8 of 12);
				}
			}

			@include service-preview-ipr-2($bp-m, $bp-l);
		}
	}
}