.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba($box-color, 0.3);
  z-index: 1000;
  transition: 0.4s linear;

  &--inner-wrapper {
    @extend %cf;
    background: $page-bg;
    max-width: $max-width;
    margin: 0 auto;
    padding: $gutter-width $dist*0.5;
    position: relative;

    @include breakpoint(null, $max-width) {
      padding: $dist*0.5 $dist*0.25;
    }

    @include breakpoint(null, $bp-s) {
      padding: $gutter-width*0.5 $dist*0.25;
    }

    span {
      width: calc(100% - #{$dist});
      height: $dist*0.125;
      background: rgba($box-color, 0.65);
      display: block;
      position: absolute;
      bottom: -$dist*0.125;

      @include breakpoint(null, $max-width) {
        width: 100%;
        left: 0;
        right: 0;
      }
    }

    &_left {
      float: left;
      border-right: 1px solid $box-color;
      padding-right: $dist;
      margin-right: $dist;

      @include breakpoint(null, $bp-l) {
        padding-right: $dist*0.5;
        margin-right: $dist*0.5;
      }

      @include breakpoint(null, $bp-nav) {
        border-right: 0;
        padding-right: 0;
        margin-right: 0;
      }

      .header__logo {
        display: inline-block;
        text-decoration: none;

        img {

          height: 42px;
          width: 100%;

          @include breakpoint(null, $bp-s) {
            max-width: 254px;
          }

          @include breakpoint(null, $bp-xs) {
            max-width: 230px;
          }
        }
      }
    }

    &_right {
      float: right;
      width: calc(100% - (301px + (#{$dist} * 2)));
      margin-top: 13px;

      @include breakpoint(null, $bp-l) {
        width: calc(100% - (301px + #{$dist}));
      }

      @include breakpoint(null, $bp-nav) {
        margin-top: 5px;
      }

      @include breakpoint(null, $bp-s) {
        margin-top: 0;
        width: auto;
      }
    }
  }
}

.header-opacity {
  background-color: #edf2f6;
  opacity: 1;
  transition: 0.4s linear;
}

.page--no-banner {

  .header {
    background-color: #edf2f6;
    opacity: 1;
  }
}