@use "sass:math";

.reference-preview {
	position: relative;
	@extend %cf;

	&:nth-child(odd) {
		.reference-preview__left {
			float: left;
			text-align: right;

			.icon {
				float: left;

				@include breakpoint(null, 650px) {
					position: absolute;
					top: $dist*0.5;
					left: $dist*0.25;
				}

				@include breakpoint(null, $bp-s) {
					top: 28px;
				}
			}
		}

		.reference-preview__big-image {
			position: relative;

			img {
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 0 $radius $radius 0;
			}

			@include breakpoint(null, $max-width) {
				margin-left: 0 !important;

				img {
					border-radius: $radius;
				}
			}
		}

		.reference-preview__right {
			float: right;
			margin-left: gutter(12);
		}
	}

	&:nth-child(even) {
		.reference-preview__left {
			float: right;
			text-align: left;

			.icon {
				float: right;

				@include breakpoint(null, 650px) {
					position: absolute;
					top: $dist*0.5;
					left: $dist*0.25;
				}

				@include breakpoint(null, $bp-s) {
					top: 28px;
				}
			}
		}

		.reference-preview__big-image {
			position: relative;

			img {
				position: absolute;
				top: 0;
				left: 0;
				border-radius: $radius 0 0 $radius;
			}

			@include breakpoint(null, $max-width) {
				margin-left: 0 !important;

				img {
					border-radius: $radius;
				}
			}
		}

		.reference-preview__right {
			float: left;
			margin-right: gutter(12);
		}
	}

	.outer-wrapper {
		@extend %cf;
		margin-top: 0;
		border-bottom: 1px solid $box-color;
		padding-bottom: $dist*2;
		margin-bottom: $dist*2.25;

		@include breakpoint(null, $max-width) {
			padding-bottom: $dist;
			margin-bottom: $dist*1.25;
		}

		@include breakpoint(null, $bp-s) {
			padding-bottom: $gutter-width;
			margin-bottom: $gutter-width*1.25;
		}
	}

	&:last-of-type {

		.outer-wrapper {
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	&__left {
		width: span(7 of 12);

		@include breakpoint(null, $bp-m) {
			width: span(6 of 12);
		}

		@include breakpoint(null, 650px) {
			width: 100%;
		}

		.icon {
			position: relative;
			z-index: 10;
			margin-top: math.div(-$dist, 1.8);
			padding: math.div($dist, 3);
			background: $ac-gradiant;
			border-radius: $radius;

			i {
				color: $page-bg;
				font-weight: $fw-light;
				font-size: $fs-h4;
			}

			@include breakpoint(null, $bp-s) {
				padding: $dist*0.25;
			}
		}

		img {
			@include resp-img;
			border-radius: $radius;
		}

		& > img {
			margin-top: $gutter-width;

			@include breakpoint(null, 650px) {
				margin-top: $dist*0.5;
			}
		}
	}

	&__right {
		width: span(5 of 12);

		@include breakpoint(null, $bp-m) {
			width: span(6 of 12);
		}

		@include breakpoint(null, 650px) {
			width: 100%;
			margin-bottom: $dist*0.5;
		}

		img {
			margin-top: $dist;
			@include resp-img;
			border-radius: $radius;

			@include breakpoint(null, $bp-m) {
				margin-top: $gutter-width;
			}
		}

		.reference-preview__text {
			h3 {
				font-family: $ff-h;
				font-size: $fs-h4;
				font-weight: $fw;
				@extend %border-bottom-small;

				@include breakpoint(null, 650px) {
					margin-left: $dist*1.75;
				}

				@include breakpoint(null, $bp-s) {
					margin-left: $gutter-width*2;
				}
			}

			p {
				border-bottom: 1px solid $box-color;
				padding-bottom: $gutter-width*0.5;
				margin-bottom: $gutter-width*0.5;
			}

			a {
				@extend %button--small;
				text-decoration: none !important;

				&:hover {
					text-decoration: underline !important;
					background: $page-bg !important;
					color: $hc !important;
				}
			}
		}
	}

	&__background {
		display: none;
		height: 35%;
		width: 50%;
		position: absolute;
		background: $box-color;
		right: 15%;
		border-radius: $radius;
		@include center(v);
		z-index: 0;
	}
}